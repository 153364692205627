import React, { useState, useEffect } from "react";
//lang
import { connect } from "react-redux";
import { translation } from "../../I18n/i18n";
import { setLangSelected } from "../../redux/actions/lang";
// reactstrap components
import {
  Card,
  CardImg,
  CardBody,
  CardTitle,
  Button,
  CardHeader,
  Container,
  Row,
  Col,
} from "reactstrap";

import { getKnowledge } from "../../redux/actions/data";

import * as _ from "lodash";

const Knowledge = (props) => {
  const { knowledge, language } = props;

  const entierAleatoire = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  useEffect(() => {
    props.getKnowledge();
  }, []);
  const entier = entierAleatoire(0, 4);
  return (
    <>
      <div className="border-0">
        <Container fluid>
          <h2 className="text-white">{translation(language, "ARTICLE.new")}</h2>
          <div className="header-body">
            <Row>
              {!knowledge ? (
                <Col>
                  <Card className="mb-4 p-3 mb-xl-0 bg-primary text-center text-white">
                    <CardBody>
                      <p className="font-weight-bold">Le saviez vous ?</p>
                      <hr></hr>
                      <p>Pas de nouvelles</p>
                    </CardBody>
                  </Card>
                </Col>
              ) : (
                <Col>
                  <Card className="mb-4 p-3 mb-xl-0 bg-primary text-center text-white">
                    <CardBody>
                      <p className="font-weight-bold">Le saviez vous ?</p>
                      <hr></hr>
                      <p>
                        {entier
                          ? knowledge[entier].description
                          : "chargement.."}
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              )}
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.langReducer.language,
    status: state.dataReducer.status,
    errors: state.dataReducer.errors,
    knowledge: state.dataReducer.knowledge,
    statusAuth: state.authReducer.status,
    refreshToken: state.authReducer.refreshToken,
  };
};
export default connect(mapStateToProps, {
  setLangSelected,
  getKnowledge,
})(Knowledge);
