import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import axios from "axios";

//redux
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import configureStore, { history } from "./redux/store.js";
import { PersistGate } from "redux-persist/integration/react";
//firebase
import firebase from "firebase/app";

export const { store, persistor } = configureStore();

// firebase.initializeApp({
//   apiKey: "AIzaSyCeuCG-hag4sbtIZ7y0kyXpEQ00gdgQyuM",
//   authDomain: "apiago-app.firebaseapp.com",
//   databaseURL: "https://apiago-app-default-rtdb.firebaseio.com",
//   projectId: "apiago-app",
//   storageBucket: "apiago-app.appspot.com",
//   messagingSenderId: "806164482558",
//   appId: "1:806164482558:web:9a7b73210a6da569ac58af",
//   measurementId: "G-10MVY6KJ7L",
// });
// export const persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
