//git
import axios from "axios";
//components
import {
  HEADER,
  GET_USER_URL,
  UPDATE_USER_DATA_URL,
  UPDATE_PASSWORD_URL,
  SEND_MESSAGE_URL,
} from "../constants";
import {
  fetchStatus,
  fetchErrors,
  setUser,
  setPaid,
} from "../reducers/authReducer";
import { firebase } from "../../firebase/config";

export const updateUserData = (user) => {
  return (dispatch) => {
    firebase
      .firestore()
      .collection("Users")
      .doc(user.id)
      .update({
        username: user.username,
        countryName: user.countryName,
        firstname: user.firstname,
        lastname: user.lastname,
        username: user.username,
        adress1: user.adress1,
        adress2: user.adress2,
        city: user.city,
        postalCode: user.postalCode,
      })
      .then(() => {
        dispatch(setUser(user));
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
};

export const getUserData = (userId) => {
 // console.log("userData");
  return (dispatch) => {
    firebase
      .firestore()
      .collection("Users")
      .doc(userId)
      .get()
      .then((firestoreDocument) => {
        const user = firestoreDocument.data();
        dispatch(setUser(user));
       // console.log("user:", user);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
};

export const updateUserDataSubscribe = (user) => {
  return (dispatch) => {
    firebase
      .firestore()
      .collection("Users")
      .doc(user.id)
      .update({
        firstname: user.firstname,
        lastname: user.lastname,
        username: user.username,
        adress1: user.adress1,
        city: user.city,
        postalCode: user.postalCode,
        abonnement: user.abonnement,
        hiveId: user.hiveId,
        paid: user.paid,
        more: user.more,
      })
      .then(() => {
        dispatch(setUser(user));
        dispatch(setPaid(false));
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
};

export const updatePaid = (user) => {
  return (dispatch) => {
    firebase
      .firestore()
      .collection("Users")
      .doc(user)
      .update({
        paid: true,
      })
      .then(() => {
        dispatch(setPaid(true));
        getUserData(user);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
};

// export const sendMessage = (photo, message) => {
//   return dispatch => {
//     return axios
//       .post(
//         SEND_MESSAGE_URL,
//         photo ? createFormData(photo, message) : message,
//         HEADER,
//       )
//       .then(res => {
//         const status = res.data.status;
//         const errors = res.data.errors;

//         if (status == 'error') {
//           dispatch(fetchStatus(status));
//           dispatch(fetchErrors(errors));
//         }
//         if (status == 'succes') {
//           dispatch(fetchStatus(status));
//           dispatch(fetchErrors({}));
//         }
//       })
//       .catch(err => {
//         console.log('err', err);
//       });
//   };
// };
