import React from "react";
//lang
import { connect } from "react-redux";
import { translation } from "../../I18n/i18n";
import { setLangSelected } from "../../redux/actions/lang";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

class UserHeader extends React.Component {
  render() {
    return (
      <>
        <div
          className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
          style={{
            minHeight: "300px",
            backgroundImage:
              "url(" +
              require("assets/img/background/abeille1.jpg").default +
              ")",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          {/* Mask */}
          <span className="mask bg-mi-transparent opacity-8" />
          {/* Header container */}
          <Container className="d-flex align-items-center" fluid>
            <Row>
              <Col md="12">
                <h1 className="display-2 text-white">Bonjour</h1>
                <p className="text-white ">
                  Mettez à jour ou modifiez vos informations personnelles ici!{" "}
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    language: state.langReducer.language,
  };
};
export default connect(mapStateToProps, { setLangSelected })(UserHeader);
