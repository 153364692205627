import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Row,
  Col,
  FormText,
} from "reactstrap";

//redux lang
import { connect } from "react-redux";
import { translation } from "../../I18n/i18n";
import { setLangSelected } from "../../redux/actions/lang";
//other
import * as _ from "lodash";
import ReactCodeInput from "react-verification-code-input";
// reactstrap components
import { addHive, hiveExist } from "../../redux/actions/hive";

const AddHiveModal = (props) => {
  const { language, errors, user, status, owner } = props;
  const [isLoading, setLoading] = useState(false);
  const [secure, setSecure] = useState(false);
  const [showModal, setShowModal] = useState("");
  const [showOwner, setShowOwner] = useState("");
  const [hiveId, setHiveId] = useState(null);
  const [hiveName, setHiveName] = useState(null);

  const [modal, setModal] = useState(false);
  const [nestedModal, setNestedModal] = useState(false);
  const [closeAll, setCloseAll] = useState(false);

  const toggle = () => setModal(!modal);

  const toggleNested = () => {
    setNestedModal(!nestedModal);
    setCloseAll(false);
  };
  const toggleAll = () => {
    setNestedModal(!nestedModal);
    setCloseAll(true);
  };
  const onSubmit = async () => {
    const hive = _.clone({
      hiveId: hiveId,
      hiveName: hiveName,
    });

    try {
      await props.hiveExist(hive, user);
      if (owner === true) {
        this.closeModal();
        setLoading(true);
      }
      // if (owner === false) {
      //   setLoading(false);
      //   showModalOwner(true);
      // }
     
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Button className="bg-primary border-0 text-white" onClick={toggle}>
        {translation(language, "ACCOUNT.addHive")}
      </Button>

      <Modal
        isOpen={modal}
        toggle={toggle}
        className="card overflow modal-dialog"
      >
        <ModalHeader toggle={toggle}>
          {translation(language, "ACCOUNT.addHive")}
        </ModalHeader>
        <ModalBody className="bg-secondary">
          <FormGroup>
            <div className="text-center">
              <p className="text-muted">
                {translation(language, "ADDHIVE.sub1")}
              </p>

              <Col md="6" className="telcenter">
                <Input
                  className="form-control-alternative"
                  placeholder={null}
                  onChange={(event) => setHiveId(event.target.value)}
                  type="text"
                />
              </Col>

              <div className="text-center">
                <p className="text-muted">
                  {translation(language, "ADDHIVE.sub2")}
                </p>

                <Col md="6" className="telcenter">
                  <Input
                    className="form-control-alternative"
                    placeholder={null}
                    onChange={(event) => setHiveName(event.target.value)}
                    type="text"
                  />
                </Col>
              </div>
              <Col md="12" className="mt--2">
                <FormText>
                  {errors ? (
                    <p className="h5 text-warning">
                      {translation(language, errors)}
                    </p>
                  ) : null}
                </FormText>
              </Col>

              <Button
                color="primary"
                size="md"
                onClick={onSubmit}
                loading={isLoading}
              >
                {translation(language, "ADDHIVE.add")}
              </Button>
            </div>
          </FormGroup>
          <br />
          {/* <Button color="success" onClick={toggleNested}>
            Show Nested Modal
          </Button> */}
          <Modal
            isOpen={nestedModal}
            toggle={toggleNested}
            onClosed={closeAll ? toggle : undefined}
          >
            <ModalHeader>Nested Modal title</ModalHeader>
            <ModalBody>Stuff and things</ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={toggleNested}>
                Done
              </Button>{" "}
              <Button color="secondary" onClick={toggleAll}>
                All Done
              </Button>
            </ModalFooter>
          </Modal>
          {/* <Button color="primary" onClick={toggle}>
            Do Something
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button> */}
        </ModalBody>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.langReducer.language,
    status: state.hiveReducer.status,
    errors: state.hiveReducer.errors,
    owner: state.authReducer.owner,
    user: state.authReducer.user.id,
    refreshToken: state.authReducer.refreshToken,
    statusAuth: state.authReducer.status,
  };
};
export default connect(mapStateToProps, {
  setLangSelected,
  addHive,
  hiveExist,
})(AddHiveModal);
