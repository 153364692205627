import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
//redux lang
import { connect } from "react-redux";
import { translation } from "../I18n/i18n";
import { setLangSelected } from "../redux/actions/lang";
import { getPaidHives } from "../redux/actions/hive";
// reactstrap components

// core components
import Header from "components/Headers/Header";

import NewParrainage from "../components/NewParrainage.js";
import Parrain from "components/Parrain";

const Parrainage = (props) => {
  const { user, paid, paidHive, hiveId } = props;
  useEffect(() => {
    props.getPaidHives(hiveId);
  }, []);

  return (
    <>
      {!user ? <Redirect push to="/auth/connect" /> : null}
      <Header />

      {!paid ? <NewParrainage /> : <Parrain />}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.user,
    paid: state.authReducer.user.paid,
    hiveId: state.authReducer.user.hiveId,
    paidHive: state.hiveReducer.paidHive,
  };
};
export default connect(mapStateToProps, { setLangSelected, getPaidHives })(
  Parrainage
);
