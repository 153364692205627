import {
  SET_HIVES,
  FETCH_STATUS_HIVE,
  FETCH_ERROR_HIVE,
  REMOVE_ALL_HIVE,
  SET_PROV_HIVE,
  SET_PUBLIC_HIVES,
} from "../constants";

const SET_PAID_HIVE = "SET_PAID_HIVE";

const INIT_STATE = {
  hives: [],
  publicHives: [],
  status: "",
  errors: "",
  provHive: "",
  paidHive: "",
};

let hiveId = 0;

const hiveReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_HIVES:
      return {
        hives: action.payload,
      };
    case SET_PUBLIC_HIVES:
      return {
        publicHives: action.payload,
      };
    case SET_PAID_HIVE:
      return {
        paidHive: action.payload,
      };
    case SET_PROV_HIVE:
      return {
        ...state,
        provHive: action.provHive,
      };
    case FETCH_STATUS_HIVE:
      return {
        ...state,
        status: action.status,
      };
    case FETCH_ERROR_HIVE:
      return {
        ...state,
        errors: action.errors,
      };
    case REMOVE_ALL_HIVE:
      return {
        ...state,
        provHive: "",
        hives: "",
        status: "",
        errors: "",
      };
    default:
      return state;
      break;
  }
};

//action creator
export const removeAllHive = () => {
  return {
    type: REMOVE_ALL_HIVE,
  };
};

export const setHives = (hives) => {
  return {
    type: SET_HIVES,
    payload: hives,
  };
};

export const setPublicHives = (publicHives) => {
  return {
    type: SET_PUBLIC_HIVES,
    payload: publicHives,
  };
};

export const setPaidHive = (paidHive) => {
  return {
    type: SET_PAID_HIVE,
    payload: paidHive,
  };
};

export const setProvHive = (provHive) => {
  return {
    type: SET_PROV_HIVE,
    provHive,
  };
};

export const fetchErrorsHive = (errors) => {
  return {
    type: FETCH_ERROR_HIVE,
    errors,
  };
};
export const fetchStatusHive = (status) => {
  return {
    type: FETCH_STATUS_HIVE,
    status,
  };
};

export { hiveReducer };
