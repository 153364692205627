import React from "react";
// bibliothèque
import { Container, Button, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

class Erreur extends React.Component {
  render() {
    return (
      <>
        <div className="fondM">
          <Container>
            <Row>
              <Col md="12" className="TextBaseM mx-auto">
                <div>
                  <p className="titreM">Une erreur est intervenue</p>
                </div>
                <p className="gras">
                  Désolé il y a eu une erreur avec votre inscription
                </p>

                <Button
                  color="primary"
                  size="md"
                  type="button"
                  className=" my-4 pl-5 pr-5"
                >
                  <Link to="/" className="buttonM">
                    Aller à l'accueil
                  </Link>
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default Erreur;
