import React, { useState, useEffect } from "react";
// reactstrap components
import {
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  Container,
  Row,
  Button,
  Col,
} from "reactstrap";
import * as _ from "lodash";
import { connect } from "react-redux";
import icon_ruche from "../../assets/img/icons/ruche.png";
import { getPaidHives } from "../../redux/actions/hive";
import { getUserData } from "../../redux/actions/user";
import Weather from "./Weather";

const Hivenews = (props) => {
  const { user, paidHive, paid, hiveId } = props;

  useEffect(() => {
    props.getUserData(user);

    props.getPaidHives(hiveId);
  }, []);

  return (
    <>
      <div className="header  pb-8 pt-5 pt-md-8">
        <Container fluid>
          <h2 className="text-green">Vos ruches parrainées</h2>
          <div className="header-body">
            <Row>
              {!paid ? (
                <Col sm="4" xs="12">
                  <Card className="card-stats mb-4 mb-xl-0 text-center bg-success p-1">
                    <CardBody>
                      <p className="text-white text-lg">
                        Vous n'avez pas d'abonnements
                      </p>
                      <Button
                        className="bg-primary text-white"
                        href="/admin/parrainage"
                      >
                        Parrainer une ruche
                      </Button>
                    </CardBody>
                  </Card>
                </Col>
              ) : (
                <Col sm="12" xs="12">
                  <Card className="card-stats bg-success mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <CardBody>
                          <Row>
                            <Col md="12">
                              {!paidHive ? (
                                <Row className="">
                                  <Col md="2">
                                    <img
                                      src={icon_ruche}
                                      className="icon-ruche "
                                    />
                                  </Col>
                                  <Col md="7">
                                    <h3 className="text-left text-white">
                                      chargement ...
                                    </h3>
                                  </Col>
                                </Row>
                              ) : (
                                <Row className="my-auto mx-auto">
                                  <Col md="6">
                                    <Row>
                                      <Col md="2">
                                        <img
                                          src={icon_ruche}
                                          className="icon-ruche "
                                        />
                                      </Col>
                                      <Col md="7">
                                        <h3 className="text-left text-white">
                                          {paidHive.hive_name}
                                        </h3>
                                        <p className="text-left text-white">
                                          {paidHive.full_adress}
                                        </p>
                                      </Col>
                                    </Row>
                                    <Col md="12">
                                      <img
                                        src={paidHive.image}
                                        className="img-reponsive"
                                      />
                                    </Col>
                                  </Col>

                                  <Col md="6" className="mt--3">
                                    <Weather />
                                  </Col>
                                </Row>
                              )}
                            </Col>
                          </Row>
                        </CardBody>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              )}
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    status: state.dataReducer.status,
    errors: state.dataReducer.errors,
    statusAuth: state.authReducer.status,
    user: state.authReducer.user.id,
    paid: state.authReducer.user.paid,
    hiveId: state.authReducer.user.hiveId,
    paidHive: state.hiveReducer.paidHive,
  };
};

export default connect(mapStateToProps, {
  getPaidHives,
  getUserData,
})(Hivenews);
