import React from "react";
//redux lang
import { connect } from "react-redux";
import { translation } from "../../I18n/i18n";
import { setLangSelected } from "../../redux/actions/lang";
//other
import PhoneInput from "react-phone-input-2";
import { updateUserData, getUserData } from "../../redux/actions/user";

import * as _ from "lodash";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

// core components

class UserSettings extends React.Component {
  state = {
    showPhone: false,
    isLoading: false,
    isSaved: false,
    errors: {},
    username: this.props.username ? this.props.username : null,
    firstname: this.props.firstname ? this.props.firstname : null,
    lastname: this.props.lastname ? this.props.lastname : null,
    adress1: this.props.adress1 ? this.props.adress1 : null,
    adress2: this.props.adress2 ? this.props.adress2 : null,
    city: this.props.city ? this.props.city : null,
    postalCode: this.props.postalCode ? this.props.postalCode : null,
    country: "FR",
    countryName: this.props.countryName ? this.props.countryName : "",
    countryCode: "33",
    phone: this.props.phone ? this.props.phone : null,
    phoneVerif: "",
  };

  myChangeHandler = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({ [nam]: val });
  };

  componentDidMount() {
    this.props.getUserData(this.props.user);
  }

  onSubmit = () => {
    const { user } = this.props;
    const {
      username,
      firstname,
      lastname,
      adress1,
      adress2,
      city,
      postalCode,
      countryName,
    } = this.state;

    const newUserInfo = _.clone({
      id: user,
      username: username,
      countryName: countryName,
      firstname: firstname,
      lastname: lastname,
      adress1: adress1,
      adress2: adress2,
      city: city,
      postalCode: postalCode,
    });

    this.props.updateUserData(newUserInfo);
  };

  render() {
    const {
      language,
      username,
      email,
      phone,
      firstname,
      lastname,
      adress1,
      adress2,
      countryName,
      city,
      postalCode,
    } = this.props;

    return (
      <>
        <Col className="order-xl-1 mx-auto" xl="9">
          <Card className="bg-secondary shadow overflow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">
                    {translation(language, "ACCOUNT.infos")}
                  </h3>
                </Col>
                <Col className="text-right" xs="4">
                  <Button
                    color="primary"
                    onClick={() => this.onSubmit()}
                    size="md"
                  >
                    {translation(language, "ACCOUNT.save")}
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="ml-3 mr-3">
              <Form>
                <h6 className="heading-small text-muted mb-4 text-muted m-4">
                  {translation(language, "ACCOUNT.profile")}
                </h6>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label text-green"
                          htmlFor="input-username"
                        >
                          {translation(language, "CONNECT.username")}
                        </label>
                        <br></br>
                        <Input
                          className="form-control-alternative"
                          type="text"
                          name="username"
                          onChange={this.myChangeHandler}
                          placeholder={
                            username
                              ? username
                              : translation(language, "CONNECT.username")
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label text-green "
                          htmlFor="input-email"
                        >
                          {translation(language, "CONNECT.email")}
                        </label>
                        <br></br>
                        <Input
                          className="form-control-alternative"
                          type="email"
                          name="lastname"
                          onChange={this.myChangeHandler}
                          placeholder={email ? email : "exemple@example.com"}
                          disabled={true}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label text-green"
                          htmlFor="input-first-name"
                        >
                          {translation(language, "ACCOUNT.firstname")}
                        </label>
                        <br></br>
                        <Input
                          type="text"
                          name="firstname"
                          onChange={this.myChangeHandler}
                          placeholder={
                            firstname
                              ? firstname
                              : translation(language, "ACCOUNT.firstname")
                          }
                          className="form-control-alternative"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label text-green"
                          htmlFor="input-last-name"
                        >
                          {translation(language, "ACCOUNT.lastname")}
                        </label>
                        <br></br>
                        <Input
                          className="form-control-alternative"
                          type="text"
                          name="lastname"
                          onChange={this.myChangeHandler}
                          placeholder={
                            lastname
                              ? lastname
                              : translation(language, "ACCOUNT.lastname")
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <hr className="my-4" />
                {/* Address */}
                <h6 className="heading-small text-muted m-4">
                  {translation(language, "ACCOUNT.contact")}
                </h6>
                <div className="pl-lg-4">
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <label
                          className="form-control-label text-green"
                          htmlFor="input-phone"
                        >
                          {translation(language, "ACCOUNT.phone")}
                        </label>
                        <br></br>
                        <PhoneInput
                          specialLabel={null}
                          inputClass="form-control-alternative"
                          country={"fr"}
                          name="phone"
                          //onChange={this.myChangeHandler}
                          type="number"
                          placeholder={phone ? phone : "+33 12 34 56 78"}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label
                          className="form-control-label text-green"
                          htmlFor="input-address"
                        >
                          {translation(language, "ACCOUNT.adress")}
                        </label>
                        <br></br>
                        <Input
                          className="form-control-alternative"
                          type="text"
                          name="adress1"
                          onChange={this.myChangeHandler}
                          placeholder={
                            adress1
                              ? adress1
                              : translation(language, "ACCOUNT.adress")
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label
                          className="form-control-label text-green"
                          htmlFor="input-address"
                        >
                          {translation(language, "ACCOUNT.adress2")}
                        </label>
                        <br></br>
                        <Input
                          className="form-control-alternative"
                          type="text"
                          name="adress2"
                          onChange={this.myChangeHandler}
                          placeholder={
                            adress2
                              ? adress2
                              : translation(language, "ACCOUNT.adress2")
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label text-green"
                          htmlFor="input-city"
                        >
                          {translation(language, "ACCOUNT.city")}
                        </label>
                        <br></br>
                        <Input
                          className="form-control-alternative"
                          type="text"
                          name="city"
                          onChange={this.myChangeHandler}
                          placeholder={
                            city ? city : translation(language, "ACCOUNT.city")
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label text-green"
                          htmlFor="input-country"
                        >
                          {translation(language, "ACCOUNT.country")}
                        </label>
                        <br></br>
                        <Input
                          className="form-control-alternative"
                          type="text"
                          name="country"
                          onChange={this.myChangeHandler}
                          placeholder={
                            countryName
                              ? countryName
                              : translation(language, "ACCOUNT.country")
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label text-green"
                          htmlFor="input-country"
                        >
                          {translation(language, "ACCOUNT.cp")}
                        </label>
                        <br></br>
                        <Input
                          className="form-control-alternative"
                          type="number"
                          name="postalCode"
                          onChange={this.myChangeHandler}
                          placeholder={
                            postalCode
                              ? postalCode
                              : translation(language, "ACCOUNT.cp")
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.langReducer.language,

    status: state.authReducer.status,
    errors: state.authReducer.errors,
    statusH: state.hiveReducer.status,
    errorsH: state.hiveReducer.errors,
    refreshToken: state.authReducer.refreshToken,

    username: state.authReducer.user.username,
    phone: state.authReducer.user.phone,
    email: state.authReducer.user.email,
    firstname: state.authReducer.user.firstname,
    lastname: state.authReducer.user.lastname,
    adress1: state.authReducer.user.adress1,
    adress2: state.authReducer.user.adress2,
    city: state.authReducer.user.city,
    country: state.authReducer.user.country,
    postalCode: state.authReducer.user.postalCode,

    user: state.authReducer.user.id,
  };
};
export default connect(mapStateToProps, {
  setLangSelected,
  updateUserData,

  getUserData,
})(UserSettings);
