import React from "react";
// reactstrap components
import { Container, Row, Col } from "reactstrap";
import { Redirect } from "react-router-dom";
//lang
import { connect } from "react-redux";
import { translation } from "../I18n/i18n";
import { setLangSelected } from "../redux/actions/lang";
// core components
import Weather from "components/Cards/Weather";
import DetailHeader from "components/Headers/DetailHeader";
import Temperature from "components/Cards/Temperature";

class Detail extends React.Component {
  render() {
    const { language, user } = this.props;
    return (
      <>
        {!user ? <Redirect push to="/auth/connect" /> : null}
        <DetailHeader />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col md="4">
              <Weather />
            </Col>
            <Col md="8">
              <Temperature />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.langReducer.language,
    user: state.authReducer.user,
  };
};
export default connect(mapStateToProps, { setLangSelected })(Detail);
