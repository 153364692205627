import React, { useState } from "react";
//lang
import { connect } from "react-redux";
import { translation } from "../../I18n/i18n";
import { setLangSelected } from "../../redux/actions/lang";
// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  FormText,
  Input,
  InputGroup,
  Row,
  Col,
} from "reactstrap";

//redux

import { register } from "../../redux/actions/auth";
import * as _ from "lodash";

const RegisterForm = (props) => {
  const { language, errors } = props;
  const [isLoading, setLoading] = useState(false);
  const [secure, setSecure] = useState(false);
  const [errorsCheck, setErrorsCheck] = useState(false);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [checked, setChecked] = useState(false);

  const onSubmit = () => {
    setLoading(true);

    if (checked) {
      props.register(email, password, username);
    } else {
      setErrorsCheck(true);
    }
  };

  const onEmailChange = (value) => {
    setEmail(value);
  };

  const onUsernameChange = (value) => {
    setUsername(value);
  };
  const onPwChange = (value) => {
    setPassword(value);
  };

  const isChecked = (e) => {
    const ischecked = e.target.checked;
    if (ischecked) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  };

  return (
    <>
      <div className="text-center text-red text-xl font-weight-700 text-uppercase mb-2 mt-4">
        <large> {translation(language, "CONNECT.register?")}</large>
      </div>
      <Form role="form">
        <FormGroup>
          <FormText>
            {errors ? (
              <p className="h5 text-warning">{translation(language, errors)}</p>
            ) : null}
            {errorsCheck ? (
              <p className="h5 text-warning">
                Vous devez acceptez les conditions d'utilisation
              </p>
            ) : null}
          </FormText>
          <small> {translation(language, "CONNECT.username")}</small>
          <InputGroup className="input-group-alternative">
            <Input
              placeholder={translation(language, "CONNECT.username")}
              onChange={(e) => onUsernameChange(e.target.value)}
              type="text"
            />
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <small> {translation(language, "CONNECT.email")}</small>
          <InputGroup className="input-group-alternative">
            <Input
              placeholder={translation(language, "CONNECT.email")}
              type="email"
              onChange={(e) => onEmailChange(e.target.value)}
              autoComplete="new-email"
            />
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <small> {translation(language, "CONNECT.pw")}</small>
          <InputGroup className="input-group-alternative">
            <Input
              placeholder={translation(language, "CONNECT.pw")}
              type="password"
              onChange={(e) => onPwChange(e.target.value)}
              autoComplete="new-password"
            />
          </InputGroup>
        </FormGroup>

        <Row className="my-4">
          <Col xs="12">
            <div className="custom-control custom-control-alternative custom-checkbox">
              <input
                className="custom-control-input"
                id="customCheckRegister"
                type="checkbox"
                onClick={(e) => {
                  isChecked(e);
                }}
              />
              <label
                className="custom-control-label"
                htmlFor="customCheckRegister"
              >
                <span className="text-muted">
                  {translation(language, "CONNECT.accept")}
                  <a
                    target="_blank"
                    href="https://apiago.fr/fr/legal"
                    className="text-red"
                  >
                    {translation(language, "CONNECT.terms")}
                  </a>
                </span>
              </label>
            </div>
          </Col>
        </Row>

        <div className="text-center">
          <Button
            className="mt-4"
            color="danger"
            type="button"
            onClick={() => onSubmit()}
          >
            {isLoading
              ? "chargement..."
              : translation(language, "NAV.register")}
          </Button>
        </div>
      </Form>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.langReducer.language,
    status: state.authReducer.status,
    errors: state.authReducer.errors,
  };
};
export default connect(mapStateToProps, { setLangSelected, register })(
  RegisterForm
);
