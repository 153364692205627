//git
import axios from "axios";
import qs from "qs";
import { Redirect } from "react-router-dom";
//components
import {
  SIGN_IN_URL,
  SIGN_UP_URL,
  HEADER,
  ADD_OWNER_URL,
  VERIF_PHONE_URL,
  HEADER2,
  EMAIL_PASSWORD_URL,
  UPDATE_PASSWORD_URL,
} from "../constants";
import { KEY_OTP_APP } from "../../constants";
import {
  fetchStatus,
  fetchErrors,
  setUser,
  removeAll,
  setLang,
  setEmailVerif,
} from "../reducers/authReducer";
import {
  removeAllHive,
  fetchStatusHive,
  fetchErrorsHive,
} from "../reducers/hiveReducer";
import { removeAllData } from "../reducers/dataReducer";

import store from "../store";
import { getHives, addHive } from "./hive";
//firebase
import { firebase } from "../../firebase/config";

export const setLanguage = (lang) => {
  return (dispatch) => {
    dispatch(setLang(lang));
  };
};

export const register = (email, password, username) => {
  return (dispatch) => {
    const usersRef = firebase.firestore().collection("Users");

    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((response) => {
        const uid = response.user.uid;
        const data = {
          id: uid,
          email: email,
          username: username,
        };

        firebase
          .firestore()
          .collection("Users")
          .doc(uid)
          .set(data)
          .then(() => {
            console.log(data);
            dispatch(setUser(data));
            dispatch(fetchErrors(""));
          })

          .catch((error) => {
            alert(error);
          });
      })
      .then(() => {
        firebase.auth().currentUser.sendEmailVerification();
      })

      .then(() => {
        alert(
          "Vous avez reçu une confirmation par e-mail, merci de cliquez sur le lien pour valider votre inscription"
        );
      })
      .catch((error) => {
        if (error.code === "auth/email-already-exists") {
          dispatch(fetchErrors("VERIF.invalidEmail"));
        }
        if (error.code === "auth/invalid-email") {
          dispatch(fetchErrors("VERIF.invalidEmail"));
          // console.log('That email address is invalid!');
        }
        if (error.code === "auth/weak-password") {
          dispatch(fetchErrors("VERIF.invalidPassword"));
          // console.log('That email address is invalid!');
        }
        alert(error);
      });
  };
};

export const login = (email, password) => {
  return (dispatch) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((response) => {
        const uid = response.user.uid;
        const usersRef = firebase.firestore().collection("Users");
        usersRef
          .doc(uid)
          .get()
          .then((firestoreDocument) => {
            if (!firestoreDocument.exists) {
              alert("User does not exist anymore.");
              return;
            }
            const user = firestoreDocument.data();
            // console.log(user);
            dispatch(setUser(user));
            dispatch(fetchErrors(""));
            getHives(uid);
          })

          .then(() => {
            if (firebase.auth().currentUser.emailVerified == false) {
              alert("Merci de verifier votre email");
            } else {
              dispatch(setEmailVerif(true));
              <Redirect push to="/admin" />;
            }
          })
          .catch((error) => {
            alert(error);
          });
      })

      .catch((error) => {
        if (error.code === "auth/email-already-exists") {
          dispatch(fetchErrors("VERIF.missEmail"));
        }
        if (error.code === "auth/invalid-email") {
          dispatch(fetchErrors("VERIF.invalidEmail"));
          // console.log('That email address is invalid!');
        }
        if (error.code === "auth/weak-password") {
          dispatch(fetchErrors("VERIF.invalidEmail"));
          // console.log('That email address is invalid!');
        }
        alert(error);
      });
  };
};

export const logout = () => {
  return (dispatch) => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch(removeAll());
        dispatch(removeAllHive());
        dispatch(removeAllData());
      });
  };
};

export const addOwner = (owner, hive, user) => {
  return (dispatch) => {
    firebase
      .firestore()
      .collection("Users")
      .doc(user)
      .set({
        id: user,
        email: owner.email,
        username: owner.username,
        countryCode: owner.countryCode,
        phone: owner.phone,
        firstname: owner.firstname,
        lastname: owner.lastname,
        adress1: owner.adress1,
        adress2: owner.adress2,
        city: owner.city,
        postalCode: owner.postalCode,
      })
      .then(() => {
        firebase
          .firestore()
          .collection("Hives")
          .doc(hive.hiveId)
          .set({
            firstNameOwner: owner.firstname,
            id: hive.hiveId,
            idOwner: user,
          })
          .then(() => {
            //  console.log("sended");
            dispatch(fetchStatusHive("succes"));
            dispatch(addHive(hive, user, false));
            dispatch(getHives(user));
            dispatch(fetchErrorsHive({}));
          })
          .catch((err) => {
            console.log("err", err);
          });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
};

export const phoneSendCode = (data) => {
  return (dispatch) => {
    return axios
      .post(
        VERIF_PHONE_URL + KEY_OTP_APP + "/code/sms",
        qs.stringify(data),
        HEADER2
      )
      .then((res) => {
        //  console.log(res.data);
        const status = res.data.status;
        const message = res.data.message;

        if (status == "ERROR") {
          if (message == "ERROR_INVALID_NUMBER") {
            var errors = "invalidPhone";
          }
          if (message == "ERROR_WAIT_TO_RETRY") {
            var errors = "invalidRetry";
          }
          dispatch(fetchStatus(status));
          dispatch(fetchErrors({ invalidPhone: errors }));
        }

        if (status == "SUCCESS") {
          dispatch(fetchStatus(status));
          dispatch(fetchErrors({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
};

export const phoneVerifCode = (data) => {
  return (dispatch) => {
    return axios
      .post(
        VERIF_PHONE_URL + KEY_OTP_APP + "/verify",
        qs.stringify(data),
        HEADER2
      )
      .then((res) => {
        const status = res.data.status;
        const message = res.data.message;
        //   console.log(res.data);

        if (status == "ERROR") {
          if (message == "ERROR_INVALID_PIN_CODE") {
            var errors = "invalidCode";
          }
          if (message == "ERROR_INVALID_SESSION") {
            var errors = "invalidSession";
          }

          dispatch(fetchStatus(status));
          dispatch(fetchErrors({ invalidCode: errors, invalidPhone: errors }));
        }

        if (status == "SUCCESS") {
          dispatch(fetchStatus(status));
          dispatch(fetchErrors({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
};

export const sendEmailPassword = (email) => {
  firebase
    .auth()
    .sendPasswordResetEmail(email)
    .then(function (user) {
      alert("Please check your email...");
    })
    .catch(function (e) {
      console.log(e);
    });
};

export const updateUserPassword = (newPassword) => {
  return (dispatch) => {
    return axios
      .put(UPDATE_PASSWORD_URL, newPassword, HEADER)
      .then((res) => {
        const status = res.data.status;
        const errors = res.data.errors;

        if (status == "error") {
          dispatch(fetchStatus(status));
          dispatch(fetchErrors(errors));
        }
        if (status == "succes") {
          dispatch(fetchStatus(status));
          dispatch(fetchErrors({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
};
