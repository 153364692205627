import firebase from "firebase/app";
import "@firebase/auth";
import "@firebase/firestore";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCeuCG-hag4sbtIZ7y0kyXpEQ00gdgQyuM",
  authDomain: "apiago-app.firebaseapp.com",
  databaseURL: "https://apiago-app-default-rtdb.firebaseio.com",
  projectId: "apiago-app",
  storageBucket: "apiago-app.appspot.com",
  messagingSenderId: "806164482558",
  appId: "1:806164482558:web:9a7b73210a6da569ac58af",
  measurementId: "G-10MVY6KJ7L",
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const storage = firebase.storage();

export { firebase, storage };
