/*eslint-disable*/
import React from "react";
//lang
import { connect } from "react-redux";
import { translation } from "../../I18n/i18n";
import { setLangSelected } from "../../redux/actions/lang";
// reactstrap components
import { NavItem, Nava, Nav, Container, Row, Col } from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronUp,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";

class Login extends React.Component {
  componentDidMount() {
    const js = `
      function ml_webform_success_w9a9j5() {
        var $ = ml_jQuery || jQuery;
        $('.ml-subscribe-form-w9a9j5 .ml-block-success').show();
        $('.ml-subscribe-form-w9a9j5 .ml-block-form').hide();
      };
    `;
    const script = document.createElement("script");
    const scriptText = document.createTextNode(js);
    script.appendChild(scriptText);
    document.body.appendChild(script);

    const script2 = document.createElement("script");
    script2.src = "https://static.mailerlite.com/js/w/webforms.min.js?w9a9j5";
    document.body.appendChild(script2);
  }

  render() {
    return (
      <Container fluid>
        <Row className="footer">
          <Col lg="5" md="12" className="plr-5">
            <br />
            <img
              className="logo-footer"
              src="https://apiago.fr/img/LOGO_V2_BLANC.svg"
              alt="logo"
            />
            <br />
            <br />
            <p>
              <b>Apiago :</b>
              <br />
              <i>"quod flores ejus apes maxime appetunt"</i>
              <br />
              <i>"Les fleurs qui attirent les abeilles"</i>
            </p>
            <br />

            <br />
          </Col>

          <Col md="12" lg="2" className="sm-text-center">
            <br />
            <br />
            <br />
            <h4 className="text-uppercase font-20px font-bold text-white">
              menu
            </h4>
            <ul className="list-unstyled">
              <li>
                <a href="https://apiago.fr" className="text-white">
                  Accueil
                </a>
                <br></br>
                <a href="https://apiago.fr/Ruche" className="text-white">
                  La ruche Apiago
                </a>
                <br></br>
                <a href="https://apiago.fr/Particulier" className="text-white">
                  Particulier
                </a>
                <br></br>
                <a
                  href="https://apiago.fr/Entreprise-et-collectivite"
                  className="text-white"
                >
                  Entreprise & Collectivite
                </a>
                <br></br>
                <a href="https://apiago.fr/Cueilleur" className="text-white">
                  Cueilleur d'essaim
                </a>
                <br></br>
                <a href="https://apiago.fr/taberna" className="text-white">
                  Magasin
                </a>
                <br></br>
                <a href="https://apiago.fr/Contact" className="text-white">
                  Contact
                </a>
              </li>
            </ul>
          </Col>

          <Col md="12" lg="2" className="sm-text-center">
            <br />
            <br />
            <br />
            <h4 className="text-uppercase font-20px font-bold text-white">
              infos
            </h4>
            <ul className="list-unstyled">
              <li>
                <a
                  href="https://apiago.fr/Mentions-légales"
                  className="text-white"
                  target="_blank"
                >
                  Mentions légales{" "}
                </a>
              </li>
              <li>
                <a
                  href="https://apiago.fr/Cgv"
                  className="text-white"
                  target="_blank"
                >
                  Condition générales de ventes{" "}
                </a>
              </li>
            </ul>
          </Col>

          <Col md="12" lg="2" className="sm-text-center">
            <br />
            <br />
            <br />
            <h4 className="text-uppercase font-20px font-bold text-white">
              social
            </h4>
            <ul className="list-unstyled">
              <li>
                <a
                  className="text-white"
                  href="https://www.youtube.com/channel/UC3xfvJNMZcd_oNUpkDXUqYg"
                  target="_blank"
                >
                  Youtube
                </a>
              </li>
              <li>
                <a
                  className="text-white"
                  href="https://www.facebook.com/ApiagoHive"
                  target="_blank"
                >
                  Facebook
                </a>
              </li>
              <li>
                <a
                  className="text-white"
                  href="https://www.linkedin.com/company/apiago"
                  target="_blank"
                >
                  Linkedin
                </a>
              </li>
              <li>
                <a
                  className="text-white"
                  href="https://www.instagram.com/apiago/"
                  target="_blank"
                >
                  Instagram
                </a>
              </li>
            </ul>
          </Col>
        </Row>

        <Row className="row copyright justify-content-center">
          <p className="text-center mx-auhref my-auhref text-white">
            Copyright © 2022 Apiago - Tous droits réservés
          </p>
        </Row>
      </Container>
    );
  }
}

const mapStatehrefProps = (state) => {
  return {
    language: state.langReducer.language,
  };
};
export default connect(mapStatehrefProps, { setLangSelected })(Login);
