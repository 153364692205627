import {
  SET_ARTICLES,
  SET_KNOWLEDGE,
  FETCH_ERROR_DATA,
  FETCH_STATUS_DATA,
  REMOVE_ALL,
  SET_TEMPERATURES,
  SET_WEATHER,
  SET_WEIGHTS,
  SELECTED_HIVE,
  SET_HIVE_DATA,
  SET_GPS,
  SET_HUMIDITIES,
  REMOVE_DATA,
} from "../constants";

const INIT_STATE = {
  selectedHive: "",
  articles: "",
  status: "",
  errors: "",
  temperatures: "",
  weather: "",
  gps: "",
  weights: "",
  hiveData: "",
  knowledge: "",
  humidities: "",
};

const dataReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_ARTICLES:
      return {
        ...state,
        articles: action.articles,
      };
    case SET_KNOWLEDGE:
      return {
        ...state,
        knowledge: action.knowledge,
      };
    case SELECTED_HIVE:
      return {
        ...state,
        selectedHive: action.selectedHive,
      };
    case SET_HIVE_DATA:
      return {
        ...state,
        hiveData: action.hiveData,
      };
    case SET_TEMPERATURES:
      return {
        ...state,
        temperatures: action.temperatures,
      };
    case SET_WEATHER:
      return {
        ...state,
        weather: action.weather,
      };
    case SET_GPS:
      return {
        ...state,
        gps: action.gps,
      };
    case SET_WEIGHTS:
      return {
        ...state,
        weights: action.weights,
      };
    case SET_HUMIDITIES:
      return {
        ...state,
        humidities: action.humidities,
      };
    case FETCH_STATUS_DATA:
      return {
        ...state,
        status: action.status,
      };
    case FETCH_ERROR_DATA:
      return {
        ...state,
        errors: action.errors,
      };
    case REMOVE_ALL:
      return {
        ...state,
        selectedHive: "",
        articles: "",
        status: "",
        errors: "",
        temperatures: "",
        weather: "",
        gps: "",
        weights: "",
        knowledge: "",
      };
    case REMOVE_DATA:
      return {
        ...state,
        temperatures: "",
        weather: "",
        gps: "",
      };
    default:
      return state;
      break;
  }
};

export const selectedHive = (selectedHive) => {
  return {
    type: SELECTED_HIVE,
    selectedHive,
  };
};

export const setHiveData = (hiveData) => {
  return {
    type: SET_HIVE_DATA,
    hiveData,
  };
};

export const setArticles = (articles) => {
  return {
    type: SET_ARTICLES,
    articles,
  };
};

export const setKnowledge = (knowledge) => {
  return {
    type: SET_KNOWLEDGE,
    knowledge,
  };
};

export const setTemperatures = (temperatures) => {
  return {
    type: SET_TEMPERATURES,
    temperatures,
  };
};

export const setWeights = (weights) => {
  return {
    type: SET_WEIGHTS,
    weights,
  };
};
export const setWeather = (weather) => {
  return {
    type: SET_WEATHER,
    weather,
  };
};

export const setGps = (gps) => {
  return {
    type: SET_GPS,
    gps,
  };
};

export const setHumidities = (humidities) => {
  return {
    type: SET_HUMIDITIES,
    humidities,
  };
};

export const fetchErrorsData = (errors) => {
  return {
    type: FETCH_ERROR_DATA,
    errors,
  };
};
export const fetchStatusData = (status) => {
  return {
    type: FETCH_STATUS_DATA,
    status,
  };
};

export const removeAllData = () => {
  return {
    type: REMOVE_ALL,
  };
};

export const removeData = () => {
  return {
    type: REMOVE_DATA,
  };
};
export { dataReducer };
