import React from "react";
// react plugin used to create google maps

// reactstrap components
import { Card, Container, Row } from "reactstrap";
import Iframe from "react-iframe";

class Maps extends React.Component {
  render() {
    return (
      <>
        <div className="border-0">
          {/* Page content */}
          <Container className="mt-5" fluid>
            <h2 className="text-black">Cartes des ruches publiques</h2>
            <Row>
              <div className="col">
                <Card className="shadow border-0 overflow">
                  <Iframe
                    src="http://map.apiago.fr/"
                    width="100%"
                    height="400"
                  ></Iframe>
                </Card>
              </div>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default Maps;
