import React from "react";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// import "assets/scss/apiago-app-web.scss";
import "assets/css/apiago-app-web.css";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";

export const App: React = () => (
  <>
    <BrowserRouter>
      <Switch>
        <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
        <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
        <Redirect from="/" to="/admin/index" />
      </Switch>
    </BrowserRouter>
  </>
);
