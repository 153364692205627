const INIT_STATE = {
  language: "fr",
};

// function langReducer(state = INIT_STATE, action) {
//   switch (action.type) {
//     case "fr":
//       return {
//         language: (state.language = "fr"),
//       };
//     case "en":
//       return {
//         language: (state.language = "en"),
//       };
//     default:
//       return state;
//   }
// }

// export default langReducer;

const langReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "SET_LANG":
      return {
        language: action.language,
      };
    default:
      return state;
  }
};

export { langReducer };

export const setLanguage = (language) => {
  return {
    type: "SET_LANG",
    language,
  };
};
