import React from "react";
//lang
import { connect } from "react-redux";
import { translation } from "../../I18n/i18n";
import { setLangSelected } from "../../redux/actions/lang";
// reactstrap components
import { Button, Input, InputGroup, FormGroup, FormText } from "reactstrap";

//redux
import * as _ from "lodash";
import { sendEmailPassword } from "../../redux/actions/auth";

class ForgotPasswordForm extends React.Component {
  _isMounted = false;

  state = {
    isLoading: false,
    errors: {
      email: "",
    },
    email: "",
  };

  onEmailChange(value) {
    this.setState({
      email: value,
    });
  }

  onSubmit = () => {
    const { email } = this.state;

    this.props.sendEmailPassword(email);
  };

  render() {
    const { language, status } = this.props;
    const { errors } = this.state;
    return (
      <>
        <div className="text-center text-primary mt-4 mb-1">
          <large> {translation(language, "CONNECT.forgot")}</large>
        </div>
        <div className="text-center mb-4 text-gray">
          <small> {translation(language, "CONNECT.forgotSub")}</small>
        </div>
        <FormGroup className="mb-5">
          <small> {translation(language, "CONNECT.email")}</small>

          <InputGroup className="input-group-alternative">
            <Input
              placeholder={translation(language, "CONNECT.email")}
              type="email"
              onChange={(e) => this.onEmailChange(e.target.value)}
              autoComplete="new-email"
            />
          </InputGroup>
          <FormText>
            {errors.email ? (
              <p className="h5 text-warning">
                {translation(language, "VERIF." + errors.email)}
              </p>
            ) : null}
          </FormText>
          <FormText>
            {status === "succes" ? (
              <p className="h5 text-green">
                {translation(language, "CONNECT.sendPw")}
              </p>
            ) : null}
          </FormText>
        </FormGroup>
        <div className="text-center">
          <Button
            className="mt--4"
            color="primary"
            type="button"
            onClick={this.onSubmit}
          >
            {translation(language, "CONNECT.forgotButton")}
          </Button>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    language: state.langReducer.language,
    status: state.authReducer.status,
    errors: state.authReducer.errors,
  };
};
export default connect(mapStateToProps, { setLangSelected, sendEmailPassword })(
  ForgotPasswordForm
);
