import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { routerMiddleware} from "connected-react-router";
import { createBrowserHistory } from "history";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "./reducers";
import { composeWithDevTools } from "redux-devtools-extension";

export const history = createBrowserHistory();
const middlewares = [routerMiddleware(history), thunk, logger];
const enhancers = [applyMiddleware(...middlewares)];

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["router"],
};
const persistedReducer = persistReducer(persistConfig, rootReducer(history));

export default (preloadedState) => {
  const store = createStore(
    persistedReducer,
    preloadedState,
    composeWithDevTools(...enhancers)
  );
  const persistor = persistStore(store);

  return { store, persistor };
};

// export const history = createBrowserHistory();

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// // export const store = createStore(
// //   createRootReducer,
// //   preloadedState,
// //   applyMiddleware(ReduxThunk, logger)
// // );

// export default function configureStore(preloadedState) {
//   const store = createStore(
//     createRootReducer(history),
//     preloadedState,

//     composeEnhancers(
//       applyMiddleware(routerMiddleware(history), ReduxThunk, logger)
//     )
//   );
//   return store;
// }
