import axios from "axios";
import {
  ARTICLE_URL,
  HEADER,
  TEMPERATURE_URL,
  WEATHER_URL,
  GPS_URL,
  WEIGHT_URL,
  HUMIDITIES_URL,
  GET_HIVE_DATA_URL,
  KNOWLEDGE_URL,
} from "../constants";
import { KEY_WEATHER } from "../../constants/";
import {
  fetchStatusData,
  fetchErrorsData,
  setArticles,
  setTemperatures,
  setWeights,
  setWeather,
  setGps,
  setHumidities,
  selectedHive,
  setHiveData,
  setKnowledge,
} from "../reducers/dataReducer";
import * as _ from "lodash";
//firebase
import { firebase } from "../../firebase/config";
import firestore from "@react-firebase/firestore";

export const setHiveSelected = (hiveId) => {
  return (dispatch) => {
    dispatch(selectedHive(hiveId));
  };
};

export const getHiveData = (hiveId) => {
  return (dispatch) => {
    return axios
      .get(GET_HIVE_DATA_URL, { params: hiveId }, HEADER)
      .then((res) => {
        const status = res.data.status;
        const errors = res.data.errors;
        const data = res.data.hiveData;

        if (status == "error") {
          //    console.log("test:", errors);
          dispatch(fetchStatusData(status));
          dispatch(fetchErrorsData(errors));
        }
        if (status == "succes") {
          dispatch(setHiveData(data));
          dispatch(fetchStatusData(status));
          dispatch(fetchErrorsData({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
};

export const getArticles = () => {
  return (dispatch) => {
    firebase
      .firestore()
      .collection("Articles")
      .get()
      .then(
        (querySnapshot) => {
          const articles = [];
          querySnapshot.forEach((doc) => {
            const article = _.clone({
              title: doc.data().title,
              description: doc.data().description,
              source: doc.data().source,
              image: doc.data().image,
            });
            articles.push(article);
          });
          dispatch(setArticles(articles));
        },
        (error) => {
          console.log(error);
        }
      );
  };
};

export const getKnowledge = () => {
  return (dispatch) => {
    firebase
      .firestore()
      .collection("Knowledges")
      .get()
      .then(
        (querySnapshot) => {
          const knows = [];
          querySnapshot.forEach((doc) => {
            const know = _.clone({
              description: doc.data().description,
            });
            knows.push(know);
          });
          dispatch(setKnowledge(knows));
          //  console.log(knows);
        },
        (error) => {
          console.log(error);
        }
      );
  };
};

export const getWeather = (gps) => {
  const lon = gps.g_longitude;
  const lat = gps.g_latitude;

  const ville = gps;
  // console.log("testlon", lon);
  //console.log("testlat", lat);

  return (dispatch) => {
    return axios
      .get(
        // WEATHER_URL + "q=" + ville + "&appid=" + KEY_WEATHER + "&units=metric"
        "https://api.openweathermap.org/data/2.5/weather?q=" +
          ville +
          "&appid=" +
          KEY_WEATHER +
          "&units=metric"
      )

      .then((res) => {
        // console.log(res);
        const cod = res.data.cod;
        const weatherInfo = {
          temperature: Math.round(res.data.main.temp),
          weather: res.data.weather[0].main,
          pression: res.data.main.pressure,
          wind: res.data.wind.speed,
          city: res.data.name,
        };
        if (cod == 200) {
          const status = "succes";
          dispatch(fetchStatusData(status));
          dispatch(setWeather(weatherInfo));
          dispatch(fetchErrorsData({}));
        }
        if (cod == 401) {
          const status = "error";
          dispatch(fetchStatusData(status));
          dispatch(fetchErrorsData("erreur"));
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
};

export const getTemperatures = (hiveId) => {
  return (dispatch) => {
    return (
      axios
        //.get(TEMPERATURE_URL, {params: hiveId}, HEADER)
        .get(
          "https://api.tetradsensors.com/requestData?src=all&field=temperature&device=3C71BF49EED4&start=2021-05-11T14:00:00Z&end=2021-05-17T14:10:00Z"
        )
        .then((res) => {
          // console.log(res.data[0]);

          // const status = res.data.status;
          // const errors = res.data.errors;
          const temperatures = res.data;

          // if (status == 'error') {
          // dispatch(fetchStatusData(status));
          //   dispatch(fetchErrorsData(errors));
          // }
          // if (status == 'succes') {
          dispatch(setTemperatures(temperatures));
          dispatch(fetchStatusData("succes"));
          // }
        })
        .catch((err) => {
          console.log("err", err);
        })
    );
  };
};

export const getWeights = (hiveId) => {
  return (dispatch) => {
    return axios
      .get(WEIGHT_URL, { params: hiveId }, HEADER)
      .then((res) => {
        const status = res.data.status;
        const errors = res.data.errors;
        const weights = res.data.weights;

        if (status == "error") {
          dispatch(fetchStatusData(status));
          dispatch(fetchErrorsData(errors));
        }
        if (status == "succes") {
          dispatch(setWeights(weights));
          dispatch(fetchStatusData(status));
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
};

export const getGps = () => {
  return (dispatch) => {
    return (
      axios
        //.get(GPS_URL, {params: hiveId}, HEADER)
        .get(
          "https://api.tetradsensors.com/requestData?src=all&field=temperature&device=3C71BF49EED4&start=2021-05-11T14:00:00Z&end=2021-05-11T14:10:00Z%20"
        )
        .then((res) => {
          const gps = _.clone({
            g_longitude: res.data[0].Longitude,
            g_latitude: res.data[0].Latitude,
          });

          // if (status == 'error') {
          //   dispatch(fetchStatusData(status));
          //   dispatch(fetchErrorsData(errors));
          //   dispatch(setGps({}));
          // }
          // if (status == 'succes') {
          dispatch(setGps(gps));
          //   dispatch(fetchStatusData(status));
          //   dispatch(fetchErrorsData({}));
          // }
        })
        .catch((err) => {
          console.log("err :", err);
        })
    );
  };
};

export const getHumidities = (hiveId) => {
  return (dispatch) => {
    return (
      axios
        //.get(TEMPERATURE_URL, {params: hiveId}, HEADER)
        .get(
          "https://api.tetradsensors.com/requestData?src=all&field=humidty&device=3C71BF49EED4&start=2021-05-11T14:00:00Z&end=2021-05-11T14:10:00Z"
        )
        .then((res) => {
          // console.log(res.data[0]);

          // const status = res.data.status;
          // const errors = res.data.errors;
          const humidity = res.data;

          // if (status == 'error') {
          // dispatch(fetchStatusData(status));
          //   dispatch(fetchErrorsData(errors));
          // }
          // if (status == 'succes') {
          dispatch(setHumidities(humidity));
          dispatch(fetchStatusData("succes"));
          // }
        })
        .catch((err) => {
          console.log("err", err);
        })
    );
  };
  // return dispatch => {
  //   return axios
  //     .get(HUMIDITIES_URL, {params: hiveId}, HEADER)
  //     .then(res => {
  //       const status = res.data.status;
  //       const errors = res.data.errors;
  //       const humidities = res.data.humidities;

  //       if (status == 'error') {
  //         dispatch(fetchStatusData(status));
  //         dispatch(fetchErrorsData(errors));
  //       }
  //       if (status == 'succes') {
  //         dispatch(setHumidities(humidities));
  //         dispatch(fetchStatusData(status));
  //       }
  //     })
  //     .catch(err => {
  //       console.log('err', err);
  //     });
  // };
};
