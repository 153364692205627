//Auth and user are here
import {
  SET_USER,
  SET_LANG,
  UPDATE_USER_DATA,
  FETCH_STATUS,
  FETCH_ERROR,
  REMOVE_ALL,
  SET_OWNER,
  SET_PAID,
  SET_EMAIL_VERIF,
} from "../constants";

const INIT_STATE = {
  status: "",
  errors: "",
  user: "",
  owner: "",
  lang: "",
  paid: false,
  emailVerif: false,
};

const authReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_LANG:
      return {
        ...state,
        lang: action.lang,
      };
    case SET_USER:
      return {
        ...state,
        user: action.user,
      };
    case SET_PAID:
      return {
        ...state,
        paid: action.paid,
      };
    case SET_EMAIL_VERIF:
      return {
        ...state,
        emailVerif: action.emailVerif,
      };
    case SET_OWNER:
      return {
        ...state,
        owner: action.owner,
      };
    case FETCH_STATUS:
      return {
        ...state,
        status: action.status,
      };
    case FETCH_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    case REMOVE_ALL:
      return {
        ...state,
        status: "",
        errors: "",
        user: "",
        owner: "",
        emailVerif: "",
      };
    default:
      return state;
      break;
  }
};

//action creator
export const removeAll = () => {
  return {
    type: REMOVE_ALL,
  };
};

export const setLang = (lang) => {
  return {
    type: SET_LANG,
    lang,
  };
};
export const setUser = (user) => {
  return {
    type: SET_USER,
    user,
  };
};

export const setEmailVerif = (emailVerif) => {
  return {
    type: SET_EMAIL_VERIF,
    emailVerif,
  };
};

export const setPaid = (paid) => {
  return {
    type: SET_PAID,
    paid,
  };
};
export const setOwner = (owner) => {
  return {
    type: SET_OWNER,
    owner,
  };
};
export const updateUserData = (userData) => {
  return {
    type: UPDATE_USER_DATA,
    newUserData: userData,
  };
};

export const fetchErrors = (errors) => {
  return {
    type: FETCH_ERROR,
    errors,
  };
};
export const fetchStatus = (status) => {
  return {
    type: FETCH_STATUS,
    status,
  };
};

export { authReducer };
