import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import { Redirect } from "react-router-dom";
//lang
import { connect } from "react-redux";
import { translation } from "../I18n/i18n";
import { setLangSelected } from "../redux/actions/lang";
//component
import Header from "components/Headers/Header.js";
import QuickView from "components/Headers/QuickView.js";
import Articles from "components/Cards/Articles.js";
import Maps from "components/Cards/Maps.js";
import Knowledges from "components/Cards/Knowledges";
import axios from "axios";
import Hivenews from "components/Cards/Hivenews";

class Index extends React.Component {
  render() {
    const { language, user } = this.props;
    return (
      <>
        {!user ? <Redirect push to="/auth/connect" /> : null}
        {/* <QuickView /> */}
        <Header />
        {/* Page content */}
        <Container fluid>
          <Row className="mt-5">
            <Col md="5" className="mx-auto my-auto">
              <h1 className="text-uppercase font-60 font-weight-400 font-italic">
                Bonjour
              </h1>
              <h2 className="text-uppercase font-60 font-weight-800">
                {user.username}
              </h2>
            </Col>
            <Col md="5" className="mx-auto my-auto">
              <Knowledges />
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Hivenews />
            </Col>
          </Row>
          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
              <Articles />
            </Col>
          </Row>
          <Row>
            <Col className="mb-8 mb-xl-8" xl="12">
              {/* <Maps /> */}
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.langReducer.language,
    user: state.authReducer.user,
  };
};
export default connect(mapStateToProps, { setLangSelected })(Index);
