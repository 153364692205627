import React, { useState, useEffect } from "react";
import { storage } from "../firebase/config";
// reactstrap components
import {
  Container,
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import * as _ from "lodash";

import { ListGroup } from "react-bootstrap";
import { Redirect } from "react-router-dom";
//img
import bleuet from "../assets/img/abonnement/bleuet.png";
import lavande from "../assets/img/abonnement/lavande.png";
import cosmos from "../assets/img/abonnement/cosmos.png";
import tournesol from "../assets/img/abonnement/tournesol.png";
import icon_ruche from "../assets/img/icons/ruche.png";
//lang
import { connect } from "react-redux";
import { translation } from "../I18n/i18n";
import { setLangSelected } from "../redux/actions/lang";

import { getPaidHives } from "../redux/actions/hive";
//css
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";

//Stripe
import { loadStripe } from "@stripe/stripe-js";
//carousel
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper/core";
SwiperCore.use([Pagination, Navigation]);

const Parrain = (props) => {
  const { user, paidHive, paid, hiveId } = props;
  const [numeroAbonnement, setNumeroAbonnement] = useState(1);
  const abonnements = [
    {
      title: "Membre Cosmos",
      description:
        "Ce parrainage permet, par exemple, de participer à la mise en place d’un couvert fleuri sur un espace jusque-là occupé par une pelouse tondue à ras. Les variétés choisies sont adaptées à leur environnement et permettent un retour de la floraison chaque année.",
      price: "4,50€ / mois",
      liste: ["3 pots de miels / an", "Subventions de projets"],
      img: cosmos,
      skup: "price_1JSe4fKTRoGtcVqMTUuijBHL",
      sku: "price_1JU5BuKTRoGtcVqMw3ww9lmL",
    },
    {
      title: "Membre Bleuet",
      description:
        "Ce parrainage permet, par exemple, la plantation et l’entretien d’un arbuste adapté à son environnement, abri indispensable à la biodiversité et dont la floraison assurera des ressources alimentaires indispensables au développement des pollinisateurs.",
      price: "9€ / mois",
      liste: ["6 pots de miels / an", "Subventions de projets"],
      img: bleuet,
      skup: "price_1JSe5PKTRoGtcVqMmam2THn3",
      sku: "price_1JU5CXKTRoGtcVqMXgNdvCag",
    },
    {
      title: "Membre Lavande",
      description:
        "Ce parrainage permet, par exemple, la mise en place d’un massif monofloral afin d’offrir une plus forte abondance de certaines espèces végétales favorables aux abeilles solitaires.",
      price: "18€ / mois",
      liste: ["12 pots de miels / an", "Subventions de projets"],
      img: lavande,
      skup: "price_1JSe5nKTRoGtcVqMv57Ck1fd",
      sku: "price_1JU5DSKTRoGtcVqMlilrFOZy",
    },
    {
      title: "Membre Tournesol",
      description:
        "Ce parrainage permet, par exemple, l’installation de dispositifs écologiques tels que des hôtels à insectes de taille modeste, évitant ainsi d’attirer prédateurs et parasites.",
      price: "36€ / mois",
      liste: [
        "24 pots de miels / an",
        "Subventions de projets",
        "Une mini ruche de biodiversité",
      ],
      img: tournesol,
      skup: "price_1JSe3DKTRoGtcVqMEZw8SacU",
      sku: "price_1JU5DpKTRoGtcVqMIldwkN0e",
    },
  ];

  // useEffect(() => {
  //   props.getPaidHives(hiveId);
  // }, []);

  return (
    <>
      {!user ? <Redirect push to="/auth/connect" /> : null}
      {/* Page content */}
      <Container>
        <Row className="mt-5">
          <Col md="12">
            <div class="carousel-parrainage">
              <Card>
                <CardBody className="ml-3 mr-3">
                  <h2 className=" text-muted  text-muted ml-4 mt-4">
                    Mon parrainage
                  </h2>
                  <p className="mb-2 text-muted ml-4">Vous avez actuellement</p>
                  <Row>
                    {!paidHive ? (
                      <p>Chargement...</p>
                    ) : (
                      <Col md="10" className="card-ruche-publique mx-auto">
                        <Row className="h-100">
                          <Col
                            md="3"
                            sm="12"
                            className="mx-auto my-auto text-center"
                          >
                            <img
                              src={icon_ruche}
                              className="mx-auto my-auto "
                            />
                            <h3 className="display-4">{paidHive.hive_name}</h3>
                            <p className="small">{paidHive.full_adress}</p>
                          </Col>
                          <Col md="4" sm="12" className="mx-auto my-auto">
                            <p className="text-left">{paidHive.description}</p>
                          </Col>

                          <Col
                            md="5"
                            sm="12"
                            className="mx-auto my-auto text-center"
                          >
                            <img
                              src={paidHive.image}
                              className="img-responsive"
                            />
                          </Col>
                        </Row>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    <Col
                      md="10"
                      className="card-abonnement text-center mx-auto"
                    >
                      <Row className="h-100">
                        <Col md="3" sm="12" className="mx-auto my-auto">
                          <img
                            src={abonnements[numeroAbonnement].img}
                            className="mx-auto my-auto"
                          />
                          <h3 className="text-uppercase mt-3">
                            {abonnements[numeroAbonnement].title}
                          </h3>
                        </Col>
                        <Col md="5" sm="12" className="mx-auto my-auto">
                          <p>{abonnements[numeroAbonnement].description}</p>
                          <ul>
                            {abonnements[numeroAbonnement].liste.map(
                              (item, i) => {
                                return <li key={i}>{item}</li>;
                              }
                            )}
                          </ul>
                        </Col>

                        <Col md="4" sm="12" className="mx-auto my-auto">
                          <h2 className="mb-2">
                            {abonnements[numeroAbonnement].price}
                          </h2>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.langReducer.language,
    user: state.authReducer.user,
    userId: state.authReducer.user.id,
    paidHive: state.hiveReducer.paidHive,
    hiveId: state.authReducer.user.hiveId,
  };
};
export default connect(mapStateToProps, {
  setLangSelected,
  getPaidHives,
})(Parrain);
