import React from "react";
import { Redirect } from "react-router-dom";
//redux
import { connect } from "react-redux";
import { translation } from "../../I18n/i18n";
import { setLangSelected } from "../../redux/actions/lang";
import { updateHiveData, deleteHive, getHives } from "../../redux/actions/hive";
import { updateUserData } from "../../redux/actions/user";
// reactstrap components
import {
  Button,
  CardHeader,
  Input,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
//other
import * as _ from "lodash";

class HiveSettings extends React.Component {
  state = {
    isDisabled: true,
    isDisabledHive: true,
    isLoading: false,

    isSecure: false,
    showHive: false,
    showDelete: false,
    hiveName: null,
    hiveId: null,
  };

  componentDidMount() {
    this.props.getHives(this.props.user);
  }

  toggleModal = () => {
    const { modal } = this.state;
    this.setState({
      modal: !modal,
    });
  };

  onSubmitHive = async (hive_name, hive_id) => {
    const { user } = this.props;
    const hive = _.clone({
      hiveName: hive_name,
      hiveId: hive_id,
    });

    this.setState({ isLoading: true });
    this.props.updateHiveData(user, hive);
  };

  onSubmitDelete = async (hive_id) => {
    const { user } = this.props;
    this.setState({ isLoading: true });

    try {
      await this.props.deleteHive(hive_id, user);
      this.toggleModal();
      this.setState({ isLoading: false });
    } catch (error) {}
  };

  render() {
    const { language, hives } = this.props;
    const { showHive, showUser, modal } = this.state;

    return (
      <>
        <CardHeader className="bg-white border-0">
          <div className="d-flex justify-content-between align-items-center">
            <h2>{translation(language, "ACCOUNT.hives")}</h2>
            <Button
              className={
                showHive
                  ? " bg-green float-right border-0 "
                  : "bg-primary float-right border-0 "
              }
              color="default"
              onClick={() => {
                this.setState({
                  showHive: !showHive,
                });
              }}
              size="md"
            >
              {showHive ? "Sauvegarder" : "Modifier"}
            </Button>
          </div>
        </CardHeader>
        <div className="d-flex justify-content-between">
          <Table responsive className="mb--3">
            <thead>
              <tr>
                <th>type</th>
                <th>Identifiant</th>
                <th>Nom de la ruche</th>
                <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {!hives
                ? null
                : hives.map((hive, i) => {
                    const hive_name = hive.hive_name;
                    const is_owner = hive.is_owner;
                    const hive_id = hive.hive_id.toString();
                    return (
                      <>
                        <Modal
                          isOpen={modal}
                          toggle={this.toggleModal}
                          className="card overflow modal-dialog"
                        >
                          <ModalHeader toggle={this.toggleModal}>
                            {translation(language, "ACCOUNT.del")}
                          </ModalHeader>
                          <ModalBody className="bg-secondary text-center">
                            <p className="text-muted m-4" key={i}>
                              {translation(language, "ACCOUNT.del?")}
                              {hive_id} ?
                            </p>

                            <Button color="primary" onClick={this.toggleModal}>
                              {translation(language, "ACCOUNT.no")}
                            </Button>
                            <Button
                              color="secondary"
                              onClick={() => this.onSubmitDelete(hive_id)}
                            >
                              {translation(language, "ACCOUNT.del")}
                            </Button>
                          </ModalBody>
                        </Modal>

                        <tr>
                          <th>{is_owner ? "Privé" : "publique"}</th>
                          <th>{hive_id}</th>
                          <td>
                            <Input
                              className={
                                showHive
                                  ? "form-control-alternative border-0"
                                  : "form-control-plaintext border-0 "
                              }
                              id="input-hivename"
                              placeholder={hive_name}
                              type="text"
                              onChange={(e) =>
                                this.onSubmitHive(e.target.value, hive_id)
                              }
                              disabled={!showHive}
                            />
                          </td>
                          <th>
                            <Button
                              onClick={this.toggleModal}
                              hidden={!showHive}
                              className="icon icon-shape bg-terciary text-white ml--3"
                            >
                              <i className="fas fa-trash-alt"></i>
                            </Button>
                          </th>
                        </tr>
                      </>
                    );
                  })}
            </tbody>
          </Table>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.langReducer.language,
    hives: state.hiveReducer.hives,

    status: state.authReducer.status,
    errors: state.authReducer.errors,
    statusH: state.hiveReducer.status,

    user: state.authReducer.user.id,

    username: state.authReducer.user.username,
    phone: state.authReducer.user.phone,
    email: state.authReducer.user.email,
    hives: state.hiveReducer.hives,
  };
};
export default connect(mapStateToProps, {
  setLangSelected,
  updateUserData,
  updateHiveData,
  deleteHive,
  getHives,
})(HiveSettings);
