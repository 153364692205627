import React, { useState, useEffect } from "react";
import { storage } from "../firebase/config";
// reactstrap components
import {
  Container,
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import * as _ from "lodash";

import { ListGroup } from "react-bootstrap";
import { Redirect } from "react-router-dom";
//img
import bleuet from "../assets/img/abonnement/bleuet.png";
import lavande from "../assets/img/abonnement/lavande.png";
import cosmos from "../assets/img/abonnement/cosmos.png";
import tournesol from "../assets/img/abonnement/tournesol.png";
import icon_ruche from "../assets/img/icons/ruche.png";
//lang
import { connect } from "react-redux";
import { translation } from "../I18n/i18n";
import { setLangSelected } from "../redux/actions/lang";
import { getPublicHives } from "redux/actions/hive";
import { updateUserDataSubscribe } from "redux/actions/user";
// core components
import Header from "components/Headers/Header";
//css
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
//Stripe
import { loadStripe } from "@stripe/stripe-js";
//carousel
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper/core";
SwiperCore.use([Pagination, Navigation]);

const NewParrainage = (props) => {
  //stripe
  const stripePromise = loadStripe(
    "pk_live_51IIxixKTRoGtcVqMj84Z0aZrabELxHmlbRL4stXwSdoIQHIN6jeeBUpyqDyGZ41fuzsNMPAmTwsdgenKYoGFCECl00qn94XiIm"
    //"pk_test_51IIxixKTRoGtcVqMlyOHnY2EaVZGKyktJdQgiJRTmNwWNyqakb3SH3llgRBm7yLff4icguMXvPAezmV6nSg5Pg6J00NavcJKsq"
  );
  //
  const user = props;
  const [swiperRef, setSwiperRef] = useState(null);
  const [active, setActive] = useState(1);
  //const [props.publicHives, setPublicHives] = useState([]);
  const [selectedHive, setSelectedHive] = useState(null);
  //
  const [abonnement, setAbonnement] = useState(null);
  const [numeroAbonnement, setNumeroAbonnement] = useState(1);
  //
  const [firstname, setFirstname] = useState(props.firstname);
  const [lastname, setLastname] = useState(props.lastname);
  const [adress, setAdress] = useState(props.adress);
  const [city, setCity] = useState(props.city);
  const [postalCode, setPostalCode] = useState(props.postalCode);
  const [more, setMore] = useState("");

  const abonnements = [
    {
      title: "Membre Cosmos",
      description:
        "Ce parrainage permet, par exemple, de participer à la mise en place d’un couvert fleuri sur un espace jusque-là occupé par une pelouse tondue à ras. Les variétés choisies sont adaptées à leur environnement et permettent un retour de la floraison chaque année.",
      price: "4,50€ / mois",
      liste: ["3 pots de miels / an", "Subventions de projets"],
      img: cosmos,
      skup: "price_1JSe4fKTRoGtcVqMTUuijBHL",
      sku: "price_1JU5BuKTRoGtcVqMw3ww9lmL",
    },
    {
      title: "Membre Bleuet",
      description:
        "Ce parrainage permet, par exemple, la plantation et l’entretien d’un arbuste adapté à son environnement, abri indispensable à la biodiversité et dont la floraison assurera des ressources alimentaires indispensables au développement des pollinisateurs.",
      price: "9€ / mois",
      liste: ["6 pots de miels / an", "Subventions de projets"],
      img: bleuet,
      skup: "price_1JSe5PKTRoGtcVqMmam2THn3",
      sku: "price_1JU5CXKTRoGtcVqMXgNdvCag",
    },
    {
      title: "Membre Lavande",
      description:
        "Ce parrainage permet, par exemple, la mise en place d’un massif monofloral afin d’offrir une plus forte abondance de certaines espèces végétales favorables aux abeilles solitaires.",
      price: "18€ / mois",
      liste: ["12 pots de miels / an", "Subventions de projets"],
      img: lavande,
      skup: "price_1JSe5nKTRoGtcVqMv57Ck1fd",
      sku: "price_1JU5DSKTRoGtcVqMlilrFOZy",
    },
    {
      title: "Membre Tournesol",
      description:
        "Ce parrainage permet, par exemple, l’installation de dispositifs écologiques tels que des hôtels à insectes de taille modeste, évitant ainsi d’attirer prédateurs et parasites.",
      price: "36€ / mois",
      liste: [
        "24 pots de miels / an",
        "Subventions de projets",
        "Une mini ruche de biodiversité",
      ],
      img: tournesol,
      skup: "price_1JSe3DKTRoGtcVqMEZw8SacU",
      sku: "price_1JU5DpKTRoGtcVqMIldwkN0e",
    },
  ];

  useEffect(() => {
    getPublic();
  }, []);

  const slideTo = (index) => {
    swiperRef.slideTo(index - 1, 0);
    setActive(index);
  };

  const setHive = (i) => {
    setSelectedHive(i);
  };

  const setSubscriber = (i) => {
    setAbonnement(20210 + i);
    setNumeroAbonnement(i);
    slideTo(4);
    window.scrollTo(0, 0);
  };

  const getPublic = () => {
    props.getPublicHives();
    //const hives = props.publicHives;
    //setPublicHives(hives);
  };

  const onSubmit = async () => {
    const user = _.clone({
      id: props.userId,
      firstname: firstname,
      lastname: lastname,
      adress1: adress,
      city: city,
      postalCode: postalCode,
      more: more,
      abonnement: abonnement,
      username: props.username,
      hiveId: props.publicHives[selectedHive].hive_id,
      paid: false,
    });

    props.updateUserDataSubscribe(user);

    // When the customer clicks on the button, redirect them to Checkout.
    const stripe = await stripePromise;
    const sku = abonnements[numeroAbonnement].skup;

    const session = await stripe.redirectToCheckout({
      lineItems: [{ price: sku, quantity: 1 }],
      mode: "subscription",
      successUrl: "http://connect.apiago.fr/admin/merci",
      cancelUrl: "http://connect.apiago.fr/admin/erreur",
    });
  };

  return (
    <>
      {!user ? <Redirect push to="/auth/connect" /> : null}
      {/* Page content */}
      <Container>
        <Row className="mt-5">
          <Col md="12">
            <div class="carousel-parrainage">
              <Swiper
                onSwiper={setSwiperRef}
                pagination={{
                  type: "progressbar",
                }}
                allowTouchMove={false}
                slidesPerView={1}
                navigation={true}
                className="mySwiper"
              >
                <SwiperSlide>
                  <Card>
                    <CardBody className="ml-3 mr-3">
                      <Form>
                        <h2 className=" text-muted  text-muted ml-4 mt-4">
                          Saissisez vos informations personnelles
                        </h2>
                        <p className="mb-6 text-muted ml-4">
                          Pour parrainner une ruche nous avons besoin
                          d'informations complémentaires
                        </p>
                        <div className="pl-lg-4">
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label text-green"
                                  htmlFor="input-username"
                                >
                                  Nom *
                                </label>
                                <br></br>
                                <Input
                                  className="form-control-alternative"
                                  type="text"
                                  name="name"
                                  required
                                  onChange={(e) => setLastname(e.target.value)}
                                  placeholder={
                                    lastname ? lastname : "Votre nom"
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label text-green"
                                  htmlFor="input-username"
                                >
                                  Prénom *
                                </label>
                                <br></br>
                                <Input
                                  className="form-control-alternative"
                                  type="text"
                                  required
                                  name="name"
                                  onChange={(e) => setFirstname(e.target.value)}
                                  placeholder={
                                    firstname ? firstname : "Votre prénom"
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label text-green"
                                  htmlFor="input-username"
                                >
                                  Adresse postale *
                                </label>
                                <br></br>
                                <Input
                                  className="form-control-alternative"
                                  type="text"
                                  name="name"
                                  required
                                  onChange={(e) => setAdress(e.target.value)}
                                  placeholder={
                                    adress ? adress : "Votre adresse"
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label text-green"
                                  htmlFor="input-username"
                                >
                                  Code postal *
                                </label>
                                <br></br>
                                <Input
                                  className="form-control-alternative"
                                  type="text"
                                  name="name"
                                  required
                                  onChange={(e) =>
                                    setPostalCode(e.target.value)
                                  }
                                  placeholder={
                                    postalCode ? postalCode : "Code postal"
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label text-green"
                                  htmlFor="input-username"
                                >
                                  Ville *
                                </label>
                                <br></br>
                                <Input
                                  className="form-control-alternative"
                                  type="text"
                                  required
                                  name="name"
                                  onChange={(e) => setCity(e.target.value)}
                                  placeholder={city ? city : "Nom de la ville"}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label text-green"
                                  htmlFor="input-username"
                                >
                                  Informations complémentaires
                                </label>
                                <br></br>
                                <Input
                                  className="form-control-alternative"
                                  type="text"
                                  name="name"
                                  onChange={(e) => setMore(e.target.value)}
                                  placeholder={
                                    more ? more : "Informations complémentaires"
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="11">
                              <Button
                                color="primary"
                                size="md"
                                type="button"
                                className="float-right my-4 pl-5 pr-5"
                                onClick={() => slideTo(2)}
                              >
                                Suivant
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </SwiperSlide>
                <SwiperSlide>
                  <Card>
                    <CardBody className="ml-3 mr-3">
                      <h2 className=" text-muted  text-muted ml-4 mt-4">
                        Choisir une ruche
                      </h2>
                      <p className="mb-6 text-muted ml-4">
                        Retrouvez toutes les ruches publiques près de chez vous.
                      </p>
                      {!props.publicHives ? (
                        <>
                          <Button
                            color="primary"
                            size="md"
                            type="button"
                            className="my-4 pl-5 pr-5"
                            onClick={() => getPublic()}
                          >
                            Afficher les ruches disponibles
                          </Button>
                        </>
                      ) : (
                        <Row className="my-auto mx-auto">
                          <Col md="7">
                            <ListGroup>
                              {props.publicHives.map((hives, i) => {
                                const name = hives.hive_name;
                                return (
                                  <ListGroup.Item
                                    key={i}
                                    action
                                    className={`${
                                      i == selectedHive ? "active" : "inactive"
                                    }`}
                                    onClick={() => setHive(i)}
                                  >
                                    {name}
                                  </ListGroup.Item>
                                );
                              })}
                            </ListGroup>
                          </Col>
                          <Col md="4" className="card-ruche-publique">
                            {selectedHive == null ? (
                              <p>Selectionnez une ruche</p>
                            ) : (
                              <div className="text-center">
                                <Row className="mb-3">
                                  <Col md="3">
                                    <img
                                      src={icon_ruche}
                                      className="icon-ruche "
                                    />
                                  </Col>
                                  <Col md="9">
                                    <h3 className="text-left">
                                      {
                                        props.publicHives[selectedHive]
                                          .hive_name
                                      }
                                    </h3>
                                    <p className="text-left">
                                      {
                                        props.publicHives[selectedHive]
                                          .full_adress
                                      }
                                    </p>
                                  </Col>
                                </Row>

                                <p className="text-left">
                                  {props.publicHives[selectedHive].description}
                                </p>
                                <img
                                  src={props.publicHives[selectedHive].image}
                                  className="mt-4 img-responsive"
                                />
                                <Button
                                  color="primary"
                                  size="md"
                                  className=""
                                  onClick={() => slideTo(3)}
                                >
                                  Parrainer cette ruche
                                </Button>
                              </div>
                            )}
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col>
                          <Button
                            color="primary"
                            size="md"
                            className="btn-return float-left my-4 pr-5 pl-5 ml-6"
                            onClick={() => slideTo(1)}
                          >
                            Retour
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </SwiperSlide>
                <SwiperSlide>
                  <Card>
                    <CardBody className="ml-3 mr-3">
                      <h2 className=" text-muted  text-muted ml-4 mt-4">
                        Choisir un abonnement
                      </h2>
                      <p className="mb-2 text-muted ml-4">
                        Des abonnements pour tout types
                      </p>

                      <Row>
                        {abonnements.map((item, i) => {
                          return (
                            <Col
                              md="10"
                              className="card-abonnement text-center mx-auto"
                            >
                              <Row className="h-100">
                                <Col md="3" sm="12" className="mx-auto my-auto">
                                  <img
                                    src={item.img}
                                    className="mx-auto my-auto"
                                  />
                                  <h3 className="text-uppercase mt-3">
                                    {item.title}
                                  </h3>
                                </Col>
                                <Col md="5" sm="12" className="mx-auto my-auto">
                                  <p>{item.description}</p>
                                  <ul>
                                    {item.liste.map((item, i) => {
                                      return <li key={i}>{item}</li>;
                                    })}
                                  </ul>
                                </Col>

                                <Col md="4" sm="12" className="mx-auto my-auto">
                                  <h2 className="mb-2"> {item.price}</h2>
                                  <Button
                                    color="primary"
                                    size="md"
                                    className="pl-5 pr-5"
                                    onClick={() => setSubscriber(i)}
                                  >
                                    Choisir cette formule
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                          );
                        })}
                      </Row>
                      <Row>
                        <Col>
                          <Button
                            color="primary"
                            size="md"
                            className=" btn-return float-left my-4 pr-5 pl-5 ml-6"
                            onClick={() => slideTo(1)}
                          >
                            Retour
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </SwiperSlide>
                <SwiperSlide>
                  <Card>
                    <CardBody className="ml-3 mr-3">
                      <h2 className=" text-muted  text-muted ml-4 mt-4">
                        Récapitulatif
                      </h2>
                      <p className="mb-2 text-muted ml-4">
                        Vous avez séléctionné :
                      </p>
                      <Row>
                        {selectedHive == null ? (
                          <p>Vous n'avez pas choisis de ruche</p>
                        ) : (
                          <Col md="10" className="card-ruche-publique mx-auto">
                            <Row className="h-100">
                              <Col
                                md="3"
                                sm="12"
                                className="mx-auto my-auto text-center"
                              >
                                <img
                                  src={icon_ruche}
                                  className="mx-auto my-auto "
                                />
                                <h3 className="display-4">
                                  {props.publicHives[selectedHive].hive_name}
                                </h3>
                                <p className="small">
                                  {props.publicHives[selectedHive].full_adress}
                                </p>
                              </Col>
                              <Col md="4" sm="12" className="mx-auto my-auto">
                                <p className="text-left">
                                  {props.publicHives[selectedHive].description}
                                </p>
                              </Col>

                              <Col
                                md="5"
                                sm="12"
                                className="mx-auto my-auto text-center"
                              >
                                <img
                                  src={lavande}
                                  className="mx-auto my-auto "
                                />
                              </Col>
                            </Row>
                          </Col>
                        )}
                      </Row>
                      <Row>
                        <Col
                          md="10"
                          className="card-abonnement text-center mx-auto"
                        >
                          <Row className="h-100">
                            <Col md="3" sm="12" className="mx-auto my-auto">
                              <img
                                src={abonnements[numeroAbonnement].img}
                                className="mx-auto my-auto"
                              />
                              <h3 className="text-uppercase mt-3">
                                {abonnements[numeroAbonnement].title}
                              </h3>
                            </Col>
                            <Col md="5" sm="12" className="mx-auto my-auto">
                              <p>{abonnements[numeroAbonnement].description}</p>
                              <ul>
                                {abonnements[numeroAbonnement].liste.map(
                                  (item, i) => {
                                    return <li key={i}>{item}</li>;
                                  }
                                )}
                              </ul>
                            </Col>

                            <Col md="4" sm="12" className="mx-auto my-auto">
                              <h2 className="mb-2">
                                {abonnements[numeroAbonnement].price}
                              </h2>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="text-center">
                          <Button
                            color="primary"
                            size="md"
                            className=" text-center my-4 pr-6 pl-6"
                            onClick={() => onSubmit()}
                          >
                            Passer au payement
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Button
                            color="primary"
                            size="md"
                            className="btn-return float-left my-4 pr-5 pl-5 ml-6"
                            onClick={() => slideTo(3)}
                          >
                            Retour
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </SwiperSlide>
              </Swiper>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.langReducer.language,
    user: state.authReducer.user,
    userId: state.authReducer.user.id,
    username: state.authReducer.user.username,
    publicHives: state.hiveReducer.publicHives,
    firstname: state.authReducer.user.firstname,
    lastname: state.authReducer.user.lastname,
    adress: state.authReducer.user.adress1,
    city: state.authReducer.user.city,
    country: state.authReducer.user.country,
    postalCode: state.authReducer.user.postalCode,
  };
};
export default connect(mapStateToProps, {
  setLangSelected,
  getPublicHives,
  updateUserDataSubscribe,
})(NewParrainage);
