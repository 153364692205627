import React, { useState, useEffect } from "react";
//chart
import "chartjs-plugin-zoom";
import { Line } from "react-chartjs-2";
import { Card, CardBody, Container } from "reactstrap";
import * as _ from "lodash";
//lang
import { connect } from "react-redux";
import { translation } from "../../I18n/i18n";
import { setLangSelected } from "../../redux/actions/lang";
//redux
import { getTemperatures } from "../../redux/actions/data";

const Temperature = (props) => {
  const { selectedHive, status, temperatures } = props;
  const [errors, setErrors] = useState(null);
  const [activeNav, setActiveNav] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [dateChange, setDateChange] = useState("data1");
  const [tempData, setTempData] = useState(null);

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setDateChange(dateChange === "data1" ? "data2" : "data1");
  };

  useEffect(() => {
    if (tempData == null) {
      getDataTemperature();
    }
  });

  // componentDidUpdate(prevProps) {
  //   if (props.selectedHive !== prevProps.selectedHive) {
  //     this.getDataTemperature();
  //   }
  // }

  const getDataTemperature = () => {
    setLoading(true);
    const hive = _.clone({
      hiveId: selectedHive,
    });

    props.getTemperatures(hive).finally(() => {
      if (status == "succes") {
        if (temperatures.length <= 0) {
          setTempData(null);
        } else {
          setTempData(temperatures);
          setLoading(false);
        }
      }
      if (status == "error") {
        setErrors(errors);
      }
    });
  };

  const tInt = tempData
    ? tempData.map((data) => parseInt(data.Temperature))
    : null;
  //const tExt = tempData ? tempData.map((data) => parseInt(data.tExt)) : null;
  const hours = tempData ? tempData.map((data) => data.Timestamp) : null;
  // const startDate = new Date("2020-11-27 12:00:00");

  const data = {
    labels: hours,
    datasets: [
      {
        label: "Temperature intérieure",
        data: tInt,
        fill: false,
        backgroundColor: "rgb(130,41,59)",
        borderColor: "rgba(130,41,59, 0.4)",
      },
      // {
      //   label: "Température extérieure",
      //   data: tExt,
      //   fill: false,
      //   backgroundColor: "rgb(13,104,102)",
      //   borderColor: "rgba(13,104,102,0.4)",
      // },
    ],
  };

  const options = {
    scales: {
      xAxes: [
        {
          type: "time",
          scaleLabel: {
            format: "HH:mm",
            display: true,
            labelString: "Date",
          },
        },
      ],
    },
    plugins: {
      zoom: {
        pan: {
          enabled: true,
          mode: "x",
        },
        zoom: {
          enabled: true,
          mode: "x",

          rangeMin: {
            // x: startDate,
            y: 0,
          },
          // rangeMax: {
          //   x: tempData ? hours.last : null,
          //   y: 0,
          // },
        },
      },
    },
    // scales: {
    //   xAxes: {
    //     type: "time",
    //     scaleLabel: {
    //       display: true,
    //       labelString: "Date",
    //     },

    //   },
    // },
    // plugins: {
    //   zoom: {
    //     zoom: {
    //       enabled: true,
    //       mode: "x",
    //       speed: 0.05,
    //     },
    //   },
    // },
    // scales: {
    //   xAxes: [
    //     {
    //       type: "time",
    //       ticks: {
    //         fontSize: 10,
    //       },
    //       format: "HH:mm",
    //       unit: "hour",
    //       unitStepSize: 2,
    //       time: {
    //         format: "HH:mm",
    //         unit: "hour",
    //         unitStepSize: 2,
    //         displayFormats: {
    //           minute: "mm",
    //           hour: "HH",
    //         },
    //       },
    //     },
    //   ],
    // },
    // plugins: {
    //   zoom: {
    //     pan: {
    //       enabled: true,
    //       mode: "x",
    //     },
    //     zoom: {
    //       enabled: true,
    //       mode: "x",
    //     },
    //   },
    // },
  };
  return (
    <>
      <div className="border-0">
        {/* Page content */}
        <Container className="mt-5" fluid>
          <Card className=" shadow border-0 overflow text-center">
            <h2 className="text-green text-uppercase text-center">
              Temperature
            </h2>

            {tempData == null ? (
              !isLoading ? (
                <h2 className="text-uppercase ">No data</h2>
              ) : (
                <h2 className="text-uppercase ">Loading...</h2>
              )
            ) : (
              <CardBody className="m-4">
                <Line data={data} options={options} />
              </CardBody>
            )}
          </Card>
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.langReducer.language,
    status: state.dataReducer.status,
    errors: state.dataReducer.errors,
    selectedHive: state.dataReducer.selectedHive,
    temperatures: state.dataReducer.temperatures,
    statusAuth: state.authReducer.status,
    refreshToken: state.authReducer.refreshToken,
    hives: state.hiveReducer.hives,
  };
};
export default connect(mapStateToProps, {
  setLangSelected,
  getTemperatures,
})(Temperature);
