import React from "react";
import { Redirect, StaticRouter } from "react-router-dom";
//lang
import { connect } from "react-redux";
import { translation } from "../I18n/i18n";
import { setLangSelected } from "../redux/actions/lang";
// reactstrap components
import { Button, Card, CardBody, Row, Col } from "reactstrap";

//redux
import CryptoJS from "crypto-js";
import { KEY_PW } from "../constants";
import { register } from "../redux/actions/auth";
import * as _ from "lodash";

import RegisterForm from "components/Form/RegisterForm";

class Register extends React.Component {
  _isMounted = false;

  render() {
    const { language, status, user } = this.props;
    return (
      <>
        {user ? <Redirect push to="/dashboard" /> : null}
        <Col lg="6" md="8">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              {/* <div className="text-center">
                <img
                  src={require("assets/img/brand/logo4.svg").default}
                  style={{ width: "32%" }}
                />
              </div> */}
              <div className="text-center text-primary mb-2 mt-4">
                <large> {translation(language, "CONNECT.register?")}</large>
              </div>
              <RegisterForm />
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col className="text-right" md="12">
              <a className="text-light" href="login">
                <small> {translation(language, "CONNECT.login?")}</small>
              </a>
            </Col>
          </Row>
        </Col>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    language: state.langReducer.language,
    status: state.authReducer.status,
    user: state.authReducer.user,
  };
};
export default connect(mapStateToProps, { setLangSelected, register })(
  Register
);
