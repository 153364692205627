//components
import {
  fetchStatusHive,
  fetchErrorsHive,
  setHives,
  setPublicHives,
  setProvHive,
  setPaidHive,
} from "../reducers/hiveReducer";
import * as _ from "lodash";
import { selectedHive, removeData } from "../reducers/dataReducer";
import { setOwner } from "../reducers/authReducer";
import { firebase } from "../../firebase/config";

export const addHive = (hive, user, owned) => {
  //console.log(user);

  return (dispatch) => {
  //  console.log(hive, user, owned);
    firebase
      .firestore()
      .collection("Users")
      .doc(user)
      .collection("UserHives")
      .doc(hive.hiveId)
      .set({
        hiveId: hive.hiveId,
        hiveName: hive.hiveName,
        owner: owned ? true : user,
      })
      .then(() => {
        console.log("sended");
        dispatch(fetchStatusHive("succes"));
        dispatch(getHives(user));
        // dispatch(setOwner(owner));
        dispatch(fetchErrorsHive({}));
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
};

export const hiveExist = (hive, user) => {
  const provhive = hive;
  return async (dispatch) => {
    firebase
      .firestore()
      .collection("Hives")
      .doc(hive.hiveId)
      .get()
      .then((documentSnapshot) => {
        if (documentSnapshot.exists) {
          console.log("exist");

          if (documentSnapshot.data().idOwner) {
            const owned = true;
            dispatch(setOwner(owned));
            dispatch(addHive(hive, user, owned));
          } else {
            // console.log('no owner');
            dispatch(setProvHive(hive));
            dispatch(setOwner(false));
          }
        } else {
         // console.log("noexist");
          dispatch(setOwner("error"));
          dispatch(fetchErrorsHive("VERIF.invalidHive"));
        }
      });
  };
};

export const getHives = (user) => {
  return (dispatch) => {
    firebase
      .firestore()
      .collection("Users")
      .doc(user)
      .collection("UserHives")
      .get()
      .then(
        (querySnapshot) => {
          const hives = [];
          querySnapshot.forEach((doc) => {
            const hive = _.clone({
              hive_id: doc.id,
              hive_name: doc.data().hiveName,
              is_owner: doc.data().owner,
            });
            hives.push(hive);
          });
          console.log(hives);

          dispatch(selectedHive(hives[0]));
          dispatch(setHives(hives));
        },
        (error) => {
          console.log(error);
          // dispatch(fetchStatusHive('error'));
          // dispatch(fetchErrorsHive('error'));
        }
      );
  };
};

export const updateHiveData = (user, hive) => {
  return (dispatch) => {
    firebase
      .firestore()
      .collection("Users")
      .doc(user)
      .collection("UserHives")
      .doc(hive.hiveId)
      .update({
        hiveName: hive.hiveName,
      })
      .then(() => {
        dispatch(getHives(user));
        dispatch(fetchErrorsHive({}));
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
};

export const deleteHive = (hive, user) => {
  return (dispatch) => {
    firebase
      .firestore()
      .collection("Users")
      .doc(user)
      .collection("UserHives")
      .doc(hive)
      .delete()
      .then(() => {
     //   console.log("sended");
        dispatch(fetchStatusHive("succes"));
        dispatch(getHives(user));
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
};

export const getPublicHives = () => {
  return (dispatch) => {
    firebase
      .firestore()
      .collection("Hives")
      .where("type", "==", "public")
      .get()
      .then(
        (querySnapshot) => {
          const hives = [];
          querySnapshot.forEach((doc) => {
            const hive = _.clone({
              hive_id: doc.id,
              hive_name: doc.data().hive_name,
              company_name: doc.data().company_name,
              description: doc.data().description,
              full_adress: doc.data().full_adress,
              image: doc.data().image,
            });
            hives.push(hive);
          });
         // console.log(hives);
          dispatch(setPublicHives(hives));
        },
        (error) => {
          console.log(error);
          // dispatch(fetchStatusHive('error'));
          // dispatch(fetchErrorsHive('error'));
        }
      );
  };
};

export const getPaidHives = (hiveId) => {
  return (dispatch) => {
    firebase
      .firestore()
      .collection("Hives")
      .doc(hiveId)
      .get()
      .then(
        (firestoreDocument) => {
          const hive = firestoreDocument.data();
        //  console.log("hive;", hive);
          dispatch(setPaidHive(hive));
        },

        (error) => {
          console.log(error);
          // dispatch(fetchStatusHive('error'));
          // dispatch(fetchErrorsHive('error'));
        }
      );
  };
};
