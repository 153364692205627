//BASE
const HEADER = {
  header: {
    "Content-Type": "application/json",
  },
};
const HEADER2 = {
  header: { "Content-Type": "application/x-www-url-encoded; charset=utf-8" },
};

//firebase

const REACT_APP_CONFIRMATION_EMAIL_REDIRECT = "https://connect.apiago.fr";
//AUTH
const SIGN_UP_URL = "https://apiagoapi.alwaysdata.net/api/users/register";
const SIGN_IN_URL = "https://apiagoapi.alwaysdata.net/api/users/login";
const ADD_OWNER_URL = "https://apiagoapi.alwaysdata.net/api/users/owner";
const NEW_TOKEN_URL = "https://apiagoapi.alwaysdata.net/api/users/newToken";
const VERIF_PHONE_URL = "https://api.ringcaptcha.com/";
const EMAIL_PASSWORD_URL =
  "https://apiagoapi.alwaysdata.net/api/users/emailpassword";
const UPDATE_PASSWORD_URL =
  "https://apiagoapi.alwaysdata.net/api/users/updatepassword";
//hive
const ADD_HIVE_URL = "https://apiagoapi.alwaysdata.net/api/users/hive/add";
const GET_HIVE_URL = "https://apiagoapi.alwaysdata.net/api/users/hive";
const DELETE_HIVE_URL =
  "https://apiagoapi.alwaysdata.net/api/users/hive/delete";
const UPDATE_HIVE_DATA_URL =
  "https://apiagoapi.alwaysdata.net/api/users/hive/update";
//user
const GET_USER_URL = "https://apiagoapi.alwaysdata.net/api/users/me";
const UPDATE_USER_DATA_URL =
  "https://apiagoapi.alwaysdata.net/api/users/me/update";
//data
const GET_HIVE_DATA_URL = "https://apiagoapi.alwaysdata.net/api/hives/data";
const SEND_MESSAGE_URL = "https://apiagoapi.alwaysdata.net/api/users/contact";
const ARTICLE_URL = "https://apiagoapi.alwaysdata.net/api/articles";
const KNOWLEDGE_URL = "https://apiagoapi.alwaysdata.net/api/knowledge";

const TEMPERATURE_URL =
  "https://apiagoapi.alwaysdata.net/api/hives/data/temperatures";
const GPS_URL = "https://apiagoapi.alwaysdata.net/api/hives/data/gps";
const WEATHER_URL = "https://api.openweathermap.org/data/2.5/weather?";
const WEIGHT_URL = "https://apiagoapi.alwaysdata.net/api/hives/data/weights";
const HUMIDITIES_URL =
  "https://apiagoapi.alwaysdata.net/api/hives/data/humidities";
// REMOVE
const REMOVE_ALL = "REMOVE_ALL";
const REMOVE_ALL_HIVE = "REMOVE_ALL_HIVE";
const REMOVE_DATA = "REMOVE_DATA";
// AUTH
const SET_TOKEN = "SET_TOKEN";
const SET_USER = "SET_USER";
const SET_EMAIL_VERIF = "SET_EMAIL_VERIF";
const SET_PAID = "SET_PAID";
const SET_LANG = "SET_LANG";
const SET_REFRESH_TOKEN = "SET_REFRESH_TOKEN";
const FETCH_ERROR = "FETCH_ERROR";
const FETCH_STATUS = "FETCH_STATUS";
const SET_OWNER = "SET_OWNER";
//HIVE
const SET_HIVES = "SET_HIVES";
const SET_PUBLIC_HIVES = "SET_PUBLIC_HIVES";
const SELECTED_HIVE = "SELECTED_HIVE";
const CHANGE_NAME_HIVES = "CHANGE_NAME_HIVES";
const FETCH_ERROR_HIVE = "FETCH_ERROR_HIVE";
const FETCH_STATUS_HIVE = "FETCH_STATUS_HIVE";
const SET_PROV_HIVE = "SET_PROV_HIVE";
//USER
const GET_USER = "GET_USER";
const UPDATE_USER_DATA = "UPDATE_USER_DATA";
//DATA
const SET_HIVE_DATA = "SET_HIVE_DATA";
const SET_ARTICLES = "SET_ARTICLES";
const SET_KNOWLEDGE = "SET_KNOWLEDGE";
const FETCH_ERROR_DATA = "FETCH_ERROR_DATA";
const FETCH_STATUS_DATA = "FETCH_STATUS_DATA";
const SET_TEMPERATURES = "SET_TEMPERATURES";
const SET_WEATHER = "SET_WEATHER";
const SET_GPS = "SET_GPS";
const SET_WEIGHTS = "SET_WEIGHTS";
const SET_HUMIDITIES = "SET_HUMIDITIES";
//OTHER
const SET_TOKEN_PW = "SET_TOKEN_PW";

export {
  //base
  HEADER,
  HEADER2,
  //url
  SIGN_UP_URL,
  SIGN_IN_URL,
  ADD_OWNER_URL,
  ADD_HIVE_URL,
  GET_HIVE_URL,
  DELETE_HIVE_URL,
  NEW_TOKEN_URL,
  GET_USER_URL,
  GET_HIVE_DATA_URL,
  UPDATE_USER_DATA_URL,
  UPDATE_HIVE_DATA_URL,
  ARTICLE_URL,
  KNOWLEDGE_URL,
  TEMPERATURE_URL,
  WEATHER_URL,
  GPS_URL,
  WEIGHT_URL,
  HUMIDITIES_URL,
  EMAIL_PASSWORD_URL,
  UPDATE_PASSWORD_URL,
  //remove
  REMOVE_ALL,
  REMOVE_ALL_HIVE,
  REMOVE_DATA,
  //auth
  SET_PAID,
  SET_TOKEN,
  SET_USER,
  SET_EMAIL_VERIF,
  SET_LANG,
  SET_REFRESH_TOKEN,
  FETCH_ERROR,
  FETCH_STATUS,
  SET_OWNER,
  VERIF_PHONE_URL,
  //hive
  CHANGE_NAME_HIVES,
  SELECTED_HIVE,
  SET_HIVES,
  SET_PUBLIC_HIVES,
  FETCH_ERROR_HIVE,
  FETCH_STATUS_HIVE,
  SET_PROV_HIVE,
  //user
  GET_USER,
  UPDATE_USER_DATA,
  SEND_MESSAGE_URL,
  //data
  SET_HIVE_DATA,
  SET_ARTICLES,
  SET_KNOWLEDGE,
  FETCH_ERROR_DATA,
  FETCH_STATUS_DATA,
  SET_TEMPERATURES,
  SET_WEATHER,
  SET_GPS,
  SET_WEIGHTS,
  SET_HUMIDITIES,
  //other
  SET_TOKEN_PW,
};
