import Index from "views/Index.js";
import Profile from "views/Profile.js";
import Register from "views/Register.js";
import Login from "views/Login.js";
import Detail from "views/Detail.js";
import Connect from "views/Connect.js";
import Parrainage from "views/Parrainage.js";
import Merci from "./views/Merci.js";
import Erreur from "./views/Erreur.js";

var routes = [
  {
    path: "/index",
    name: "Accueil",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin",
  },
  {
    path: "/user-profile",
    name: "Compte",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/admin",
  },
  {
    path: "/parrainage",
    name: "Parrainage",
    icon: "ni ni-circle-08 text-pink",
    component: Parrainage,
    layout: "/admin",
  },

  {
    path: "/connect",
    name: "Connect",
    icon: "ni ni-circle-08 text-pink",
    component: Connect,
    layout: "/auth",
  },
  {
    path: "/merci",
    name: "Merci",
    icon: "ni ni-circle-08 text-pink",
    component: Merci,
    layout: "/admin",
  },

  {
    path: "/erreur",
    name: "Erreur",
    icon: "ni ni-circle-08 text-pink",
    component: Erreur,
    layout: "/admin",
  },
];
export default routes;
