import React from "react";
//lang
import { connect } from "react-redux";
import { translation } from "../../I18n/i18n";
import { setLangSelected } from "../../redux/actions/lang";
// reactstrap components
import {
  Card,
  CardImg,
  CardBody,
  CardTitle,
  Button,
  CardHeader,
  Container,
  Row,
  Col,
} from "reactstrap";

import { getArticles } from "../../redux/actions/data";

import * as _ from "lodash";

class Articles extends React.Component {
  state = {
    articles: null,
  };

  componentDidMount() {
    this.getDataArticles();
  }

  getDataArticles = () => {
    this.props.getArticles();
    const articles = this.props.articles;

    this.setState({
      articles: articles,
    });
  };

  render() {
    const { articles } = this.state;
    const { language } = this.props;
    return (
      <>
        <div className="border-0">
          <Container fluid>
            <h2 className="text-white">
              {translation(language, "ARTICLE.new")}
            </h2>
            <div className="header-body">
              <Row>
                {!articles ? (
                  <Col sm="4" xs="12">
                    <Card className="shadow border-0 overflow mb-1">
                      <CardImg
                        className="mb--9"
                        src={
                          require("assets/img/background/abeille1.jpg").default
                        }
                        height="200px"
                      />
                      <CardBody>
                        <CardHeader
                          tag="p"
                          className="bg-mi-transparent text-white"
                        >
                          <CardTitle
                            tag="h3"
                            className="text-uppercase mb-0 text-white"
                          >
                            {translation(language, "ARTICLE.no")}
                          </CardTitle>
                          {translation(language, "ARTICLE.later")}
                        </CardHeader>
                      </CardBody>
                    </Card>
                  </Col>
                ) : (
                  articles.map((articles, i) => {
                    const source = articles.source;
                    const title = articles.title;
                    const description = articles.description;
                    const image = articles.image;
                    return (
                      <Col sm="4" xs="12">
                        <Card className="shadow border-0 overflow mb-1">
                          <CardImg
                            className="mb--9 img-responsive"
                            src={image}
                            height="300px"
                            width=" 100%"
                          />
                          <CardBody>
                            <CardHeader
                              tag="p"
                              className="bg-mi-transparent text-white"
                            >
                              <CardTitle
                                tag="h3"
                                className="text-uppercase mb-0 text-white"
                              >
                                {title}
                              </CardTitle>
                              <p>{description}</p>
                              <div className="text-center">
                                <Button href={source} target="_blank">
                                  {translation(language, "ARTICLE.more")}
                                </Button>
                              </div>
                            </CardHeader>
                          </CardBody>
                        </Card>
                      </Col>
                    );
                  })
                )}
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.langReducer.language,
    status: state.dataReducer.status,
    errors: state.dataReducer.errors,
    articles: state.dataReducer.articles,
    statusAuth: state.authReducer.status,
    refreshToken: state.authReducer.refreshToken,
  };
};
export default connect(mapStateToProps, {
  setLangSelected,
  getArticles,
})(Articles);
