//base
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import storage from "redux-persist/lib/storage";
//reducer
import { authReducer } from "./authReducer";
import { hiveReducer } from "./hiveReducer";
import { dataReducer } from "./dataReducer";
import { langReducer } from "./langReducer";
//git
import { persistReducer } from "redux-persist";
//custom
import { KEY_STORAGE } from "../../constants";

// const encryptor = createAsyncEncryptor({
//   secretKey: KEY_STORAGE
// });

// const rootReducer = combineReducers({
//   auth: persistReducer(authPersistConfig, authReducer),
//   hive: persistReducer(hivePersistConfig, hiveReducer),
//   data: persistReducer(dataPersistConfig, dataReducer),
//   lang: persistReducer(langPersistConfig, langReducer),
// });

// export default persistReducer(rootPersistConfig, rootReducer);
const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    authReducer: persistReducer(authPersistConfig, authReducer),
    hiveReducer: persistReducer(hivePersistConfig, hiveReducer),
    dataReducer: persistReducer(dataPersistConfig, dataReducer),
    langReducer: persistReducer(langPersistConfig, langReducer),
  });

export default createRootReducer;

// const rootReducer = persistReducer(
//   rootPersistConfig,
//   combineReducers({
//     auth: persistReducer(authPersistConfig, authReducer),
//     hive: persistReducer(hivePersistConfig, hiveReducer),
//     data: persistReducer(dataPersistConfig, dataReducer),
//     lang: persistReducer(langPersistConfig, langReducer),
//   })
// );

// export default rootReducer;

const authPersistConfig = {
  key: "auth",
  storage: storage,
  blacklist: ["errors", "status"],
};

const hivePersistConfig = {
  key: "hive",
  storage: storage,
  blacklist: ["errors", "status", "provHive"],
};

const dataPersistConfig = {
  key: "data",
  storage: storage,
};

const langPersistConfig = {
  key: "lang",
  storage: storage,
};

const rootPersistConfig = {
  key: "root",
  storage: storage,
  blacklist: ["router"],
};
