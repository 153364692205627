import React, { useState, useEffect } from "react";
// bibliothèque
import { Container, Button, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

import { updatePaid } from "redux/actions/user";
import { connect } from "react-redux";

const Merci = (props) => {
  useEffect(() => {
    props.updatePaid(props.userId);
    //console.log(props.userId);
  }, []);

  const setPaid = () => {
    props.updatePaid(props.userId);
  };

  return (
    <>
      <div className="fondM">
        <Container>
          <Row>
            <Col md="12" className="TextBaseM mx-auto">
              <div>
                <p className="titreM">Opération réussie</p>
              </div>
              <p className="gras">
                Merci pour votre participation à la conservation de la
                biodiversité !
              </p>
              <Button
                color="primary"
                size="md"
                type="button"
                className=" my-4 pl-5 pr-5"
              >
                <Link to="/" className="buttonM" onClick={() => setPaid()}>
                  Aller à l'accueil
                </Link>
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.authReducer.user.id,
  };
};
export default connect(mapStateToProps, {
  updatePaid,
})(Merci);
