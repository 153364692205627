import React, { useState } from "react";
//lang
import { connect } from "react-redux";
import { translation } from "../../I18n/i18n";
import { setLangSelected } from "../../redux/actions/lang";
// reactstrap components
import {
  Button,
  FormGroup,
  FormText,
  Form,
  Input,
  InputGroup,
} from "reactstrap";

//redux
import CryptoJS from "crypto-js";
import { KEY_PW } from "../../constants";
import { login } from "../../redux/actions/auth";

import * as _ from "lodash";

const LoginForm = (props) => {
  const { language, errors } = props;
  const [isLoading, setLoading] = useState(false);
  const [secure, setSecure] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onSubmit = () => {
    props.login(email, password);
  };

  return (
    <>
      <div className="text-center text-red text-xl font-weight-700 text-uppercase mb-2 mt-4">
        <large> {translation(language, "CONNECT.login?")}</large>
      </div>
      <Form role="form">
        <FormGroup className="mb-3">
          <FormText>
            {errors ? (
              <p className="h5 text-warning">{translation(language, errors)}</p>
            ) : null}
          </FormText>
          <small> {translation(language, "CONNECT.email")}</small>

          <InputGroup className="input-group-alternative">
            <Input
              placeholder={translation(language, "CONNECT.email")}
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="new-email"
            />
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <small> {translation(language, "CONNECT.pw")}</small>
          <InputGroup className="input-group-alternative">
            <Input
              placeholder={translation(language, "CONNECT.pw")}
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="new-password"
            />
          </InputGroup>
        </FormGroup>
        <div className="custom-control custom-control-alternative custom-checkbox">
          <input
            className="custom-control-input"
            id=" customCheckLogin"
            type="checkbox"
          />
          <label className="custom-control-label" htmlFor=" customCheckLogin">
            <span className="text-muted">
              {translation(language, "CONNECT.remember")}
            </span>
          </label>
        </div>
        <div className="text-center">
          <Button
            className="my-4"
            color="danger"
            type="button"
            onClick={() => onSubmit()}
          >
            {translation(language, "NAV.login")}
          </Button>
        </div>
      </Form>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.langReducer.language,
    status: state.authReducer.status,
    errors: state.authReducer.errors,
  };
};
export default connect(mapStateToProps, { setLangSelected, login })(LoginForm);
