import React from "react";
// reactstrap components
import {
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  Container,
  Row,
  Col,
} from "reactstrap";
import * as _ from "lodash";
import { connect } from "react-redux";
import {
  getTemperatures,
  getWeights,
  getHiveData,
  getHumidities,
  getGps,
  getWeather,
} from "../../redux/actions/data";

import { getHives } from "../../redux/actions/hive";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBatteryThreeQuarters,
  faCloud,
  faThermometerHalf,
  faTint,
  faWeight,
  faWifi,
} from "@fortawesome/free-solid-svg-icons";

import bee from "../../assets/img/icons/quick/beeWhite.png";

class QuickView extends React.Component {
  state = {
    hiveData: [
      {
        id: 1,
        battery: "60",
        health: "",
        temp: "26",
        weather: {
          temperature: 14,
          weather: "cloud",
        },
        humi: "36",
        connect: "WIFI",
        weight: "25",
      },

      {
        id: 2,
        battery: "86",
        health: "",
        temp: "35",
        weather: {
          temperature: 14,
          weather: "cloud",
        },
        humi: "46",
        connect: "WIFI",
        weight: "35",
      },
    ],

    isLoading: false,
    icon: "",
    activeSlide: 0,
  };

  // componentDidMount() {
  //   this.props.getHives(this.props.user);
  //   // this.getData();
  // }

  // getData = () => {
  //   const { selectedHive } = this.props;
  //   console.log(selectedHive);
  //   const hive = _.clone({
  //     hiveId: selectedHive,
  //   });

  //   this.setState({
  //     isLoading: true,
  //   });

  //   this.props.getTemperatures(hive);
  //   this.props.getWeights(hive);
  //   this.props.getHiveData(hive);
  //   this.props.getHumidities(hive);
  //   // this.props.getGps(hive).finally(() => {
  //   //   const gps = this.props.gps;
  //   //   gps ? this.props.getWeather(gps) : null;
  //   // });
  //   this.props.getHiveData(hive).finally(() => {
  //     const {
  //       errors,
  //       status,
  //       data,
  //       weights,
  //       temperatures,
  //       weather,
  //       humidities,
  //     } = this.props;

  //     if (status == "succes") {
  //       this.setState({
  //         isLoading: false,
  //         health: data ? data[0].health : null,
  //         weight:
  //           weights.length >= 1
  //             ? weights[weights.length - 1].weight.toString()
  //             : null,
  //         temp:
  //           temperatures.length >= 1
  //             ? temperatures[temperatures.length - 1].Temperature
  //             : null,
  //         humi:
  //           humidities.length >= 1
  //             ? humidities[humidities.length - 1].Humidity
  //             : null,

  //         weather: weather ? weather.temperature.toString() : null,
  //         battery: data ? data[0].battery.toString() : null,
  //         // icon: weather ? weatherInfo[weather.weather].icon : null,
  //       });
  //     }

  //     if (status == "error") {
  //       this.retry();
  //       this.setState({
  //         errors: errors,
  //       });
  //     }
  //   });
  // };

  render() {
    const { hives } = this.props;
    const { hiveData } = this.state;
    return (
      <>
        <div className="header bg-gradient-primary pb-8 pt-5 pt-md-8">
          <Container fluid>
            <h2 className="text-white">Vue rapide</h2>
            <div className="header-body">
              <Row>
                {!hives ? (
                  <Col sm="4" xs="12">
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardBody>
                        <CardHeader className="text-muted">
                          Vous n'avez pas de ruche
                        </CardHeader>
                      </CardBody>
                    </Card>
                  </Col>
                ) : (
                  hives.map((hive, i) => {
                    const hive_name = hive.hive_name;
                    const is_owner = hive.is_owner;
                    const hive_id = hive.hive_id.toString();
                    return (
                      <Col sm="4" xs="12">
                        <Card className="card-stats mb-4 mb-xl-0">
                          <CardBody>
                            <CardTitle tag="h3" className="text-uppercase mb-0">
                              {hive_name}
                            </CardTitle>
                            <CardHeader className="text-muted">
                              {/* Votre ruche va bien */}
                            </CardHeader>
                            <Row>
                              <CardBody>
                                <Row>
                                  <Col className="col-6 text-center">
                                    <div className="icon icon-shape bg-primary text-white rounded-circle column">
                                      {hiveData[i].temp + " °C"}
                                      <FontAwesomeIcon
                                        icon={faThermometerHalf}
                                        size="6x"
                                      />
                                    </div>
                                  </Col>
                                  <Col className="col-6 text-center">
                                    <div className="icon icon-shape bg-primary text-white rounded-circle column">
                                      {hiveData[i].humi + " %"}
                                      <FontAwesomeIcon
                                        icon={faTint}
                                        size="6x"
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col className="col-4 text-center">
                                    <div className=" icon icon-shape bg-primary text-white rounded-circle column">
                                      {hiveData[i].connect}
                                      <FontAwesomeIcon
                                        icon={faWifi}
                                        size="6x"
                                      />
                                    </div>
                                  </Col>
                                  <Col className="col-4 text-center">
                                    <div className="icon">
                                      <img
                                        src={bee}
                                        style={{ color: "#000" }}
                                      />
                                    </div>
                                  </Col>
                                  <Col className="col-4 text-center">
                                    <div className="icon icon-shape bg-primary text-white rounded-circle column">
                                      {hiveData[i].battery + " %"}
                                      <FontAwesomeIcon
                                        icon={faBatteryThreeQuarters}
                                        size="6x"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col className="col-6 text-center">
                                    <div className=" icon icon-shape bg-primary text-white rounded-circle column">
                                      {hiveData[i].weight + " Kg"}
                                      <FontAwesomeIcon
                                        icon={faWeight}
                                        size="6x"
                                      />
                                    </div>
                                  </Col>
                                  <Col className="col-6 text-center">
                                    <div className="icon icon-shape bg-primary text-white rounded-circle column">
                                      {this.props.weather.temperature + " °C"}
                                      <FontAwesomeIcon
                                        icon={faCloud}
                                        size="6x"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    );
                  })
                )}
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    status: state.dataReducer.status,
    errors: state.dataReducer.errors,
    statusAuth: state.authReducer.status,
    user: state.authReducer.user.id,

    hives: state.hiveReducer.hives,
    selectedHive: state.dataReducer.selectedHive,
    battery: state.dataReducer.hiveData.battery,
    selectedHive: state.dataReducer.selectedHive,

    data: state.dataReducer.hiveData,
    weights: state.dataReducer.weights,
    temperatures: state.dataReducer.temperatures,
    weather: state.dataReducer.weather,
    humidities: state.dataReducer.humidities,
  };
};

export default connect(mapStateToProps, {
  getHiveData,
  getTemperatures,
  getWeather,
  getWeights,
  getGps,
  getHumidities,
  getHives,
})(QuickView);
