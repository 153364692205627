import React from "react";
import { Redirect } from "react-router-dom";
//redux lang
import { connect } from "react-redux";
import { translation } from "../I18n/i18n";
import { setLangSelected } from "../redux/actions/lang";
import axios from "axios";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import UserHeader from "components/Headers/UserHeader";
// import Addhive from "components/Form/AddHiveForm";
import UserSettings from "components/Form/UserSettingsForm";
import AddhiveModal from "components/Modals/AddHiveModal";
import AddHiveSettings from "components/Form/HiveSettingsForm";
import HiveSettingsForm from "components/Form/HiveSettingsForm";

class Profile extends React.Component {
  state = {
    showHive: false,
    showUser: false,
    modal: false,
    backdrop: true,
  };

  render() {
    const { language, hives, user } = this.props;
    const { showHive, showUser } = this.state;

    return (
      <>
        {!user ? <Redirect push to="/auth/connect" /> : null}
        <UserHeader />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            {/* <Col className="order-xl-1 mb-5 mb-xl-0" xl="5">
              <Card className="bg-secondary card-profile shadow overflow text-green">
                <HiveSettingsForm />

                <CardBody className="pt-0">
                  <div className="text-center m-4">
                    <hr className="m-4" />
                    <AddhiveModal />
                  </div>
                </CardBody>
              </Card>
            </Col> */}

            {/* INFOMATIONS PERSONNELLES */}
            <UserSettings />
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.langReducer.language,
    hives: state.hiveReducer.hives,
    user: state.authReducer.user,
  };
};
export default connect(mapStateToProps, { setLangSelected })(Profile);
