import React from "react";

class Header extends React.Component {
  render() {
    return (
      <>
        <div
          className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
          style={{
            minHeight: "300px",
            backgroundImage:
              "url(" +
              require("assets/img/background/bg2.png").default +
              ")",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>
      </>
    );
  }
}

export default Header;
