import React from "react";
import { Redirect } from "react-router-dom";
//lang
import { connect } from "react-redux";
import { translation } from "../I18n/i18n";
import { setLangSelected } from "../redux/actions/lang";
// reactstrap components
import { Card, CardBody, Collapse, Row, Col } from "reactstrap";
import img from "../assets/img/accueil/img1.png";
//redux

import { register } from "../redux/actions/auth";
import { login } from "../redux/actions/auth";

import * as _ from "lodash";

import ForgotPasswordForm from "components/Form/ForgotPasswordForm.js";
import LoginForm from "components/Form/LoginForm.js";
import RegisterForm from "components/Form/RegisterForm";

class Connect extends React.Component {
  _isMounted = false;

  state = {
    forgotOpen: false,
  };

  onForgot = () => {
    this.setState({
      forgotOpen: !this.state.forgotOpen,
    });
  };

  render() {
    const { language, user, emailVerif } = this.props;
    const { forgotOpen } = this.state;
    return (
      <>
        {emailVerif ? <Redirect push to="/admin" /> : null}
        <Col md="12">
          <h1 className="text-red font-30">Connexion au compte</h1>
          <p>Rejoignez nous des maintenant ! </p>
        </Col>
        <Col md="6">
          <Col md="10" className="mb-6 mt-4">
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-5 py-5">
                <Collapse isOpen={!forgotOpen}>
                  <LoginForm />
                </Collapse>
                <Collapse isOpen={forgotOpen}>
                  <ForgotPasswordForm />
                </Collapse>
                <a className="text-gray" onClick={this.onForgot}>
                  {forgotOpen ? (
                    <small> {translation(language, "CONNECT.login?")}</small>
                  ) : (
                    <small> {translation(language, "CONNECT.forgot")}</small>
                  )}
                </a>
              </CardBody>
            </Card>
          </Col>
          <Col md="10" className="mt-10">
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-5 py-5">
                <RegisterForm />
              </CardBody>
            </Card>
          </Col>
        </Col>

        <Col md="6" className="mt-9">
          <div className="my-auto">
            <h2 className="text-primary">Rejoignez nos abonnés Apiago!</h2>
            <p>Merci pour votre engagement pour la biodiversité !</p>
            <img src={img} className="img-responsive" />
          </div>
        </Col>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    language: state.langReducer.language,
    user: state.authReducer.user,
    emailVerif: state.authReducer.emailVerif,
  };
};
export default connect(mapStateToProps, { setLangSelected, login, register })(
  Connect
);
