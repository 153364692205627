import {
  mdiGauge,
  mdiWeatherRainy,
  mdiWeatherSunny,
  mdiWeatherLightning,
  mdiWeatherCloudy,
  mdiWeatherSnowy,
  mdiWeatherFog,
  mdiWeatherHail,
  mdiBrightness6,
  mdiWeatherWindy,
} from "@mdi/js";

export const weatherInfo = {
  Rain: {
    color: "#AEC8CE",
    title: "Pluvieux",
    icon: mdiWeatherRainy,
    image: require("assets/img/illustration/weather/rain.png"),
  },
  Clear: {
    color: "#FFE09C",
    title: "Ensoleillé",
    icon: mdiWeatherSunny,
    image: require("assets/img/illustration/weather/clear.png"),
  },
  Thunderstorm: {
    color: "#616161",
    title: "Orageux",
    icon: mdiWeatherLightning,
    image: require("assets/img/illustration/weather/thunder.png"),
  },
  Clouds: {
    color: "#809AB7",
    title: "Nuageux",
    icon: mdiWeatherCloudy,
    image: require("assets/img/illustration/weather/cloud.png"),
  },
  Snow: {
    color: "#83B8E2",
    title: "Neigeux",
    icon: mdiWeatherSnowy,
    image: require("assets/img/illustration/weather/snow.png"),
  },
  Drizzle: {
    color: "#87ADC1",
    title: "Bruineux",
    icon: mdiWeatherHail,
    image: require("assets/img/illustration/weather/haze.png"),
  },
  Haze: {
    color: "#A9BCC9",
    title: "Brumeux",
    icon: mdiWeatherHail,
    image: require("assets/img/illustration/weather/haze.png"),
  },
  Mist: {
    color: "#CCCCCC",
    title: "Brouillard",
    icon: mdiWeatherFog,
    image: require("assets/img/illustration/weather/haze.png"),
  },
};
