import React, { useState } from "react";
//lang
import { connect } from "react-redux";
import { translation } from "../../I18n/i18n";
import { setLangSelected } from "../../redux/actions/lang";
import { setHiveSelected } from "../../redux/actions/data";
// reactstrap components
import { Container, Row, Col } from "reactstrap";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { setHives } from "redux/reducers/hiveReducer";

const DetailHeader = (props) => {
  const [hive, setHive] = useState("");

  const hiveSelected = (value1, value2) => {
    props.setHiveSelected(value1);
    setHive(value2);
  };

  return (
    <>
      <div className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center bg-gradient-primary">
        {/* Mask */}
        <span className="mask bg-mi-transparent opacity-8" />
        {/* Header container */}
        <Container className="d-flex align-items-center" fluid>
          <Row>
            <Col md="12">
              <h1 className="display-2 text-white">Hello</h1>
              <p className="text-white ">
                Retrouvez ici le detail des informations de vos ruches
              </p>
            </Col>

            <UncontrolledDropdown>
              <DropdownToggle caret>{hive}</DropdownToggle>
              <DropdownMenu>
                <DropdownItem header>Choisir une ruche </DropdownItem>
                <DropdownItem divider />

                {props.hives != null
                  ? props.hives.map((hive, i) => {
                      const hive_name = hive.hive_name;
                      const hive_id = hive.hive_id;
                      return (
                        <DropdownItem
                          onClick={() => {
                            hiveSelected(hive_id, hive_name);
                          }}
                        >
                          {hive_name}
                        </DropdownItem>
                      );
                    })
                  : null}
              </DropdownMenu>
            </UncontrolledDropdown>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.langReducer.language,
    hives: state.hiveReducer.hives,
  };
};

export default connect(mapStateToProps, { setLangSelected, setHiveSelected })(
  DetailHeader
);
