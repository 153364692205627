import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
//icon
import Icon from "@mdi/react";
import {
  mdiGauge,
  mdiWeatherRainy,
  mdiWeatherSunny,
  mdiWeatherLightning,
  mdiWeatherCloudy,
  mdiWeatherSnowy,
  mdiWeatherFog,
  mdiWeatherHail,
  mdiBrightness6,
  mdiWeatherWindy,
} from "@mdi/js";
// reactstrap components
import * as _ from "lodash";
import { Card, Col, Container, Row } from "reactstrap";
import { weatherInfo } from "constants/weather";

import { getGps, getWeather } from "../../redux/actions/data";
import { setLangSelected } from "../../redux/actions/lang";

const Weather = (props) => {
  const { selectedHive, weather, status, errors, paidHive } = props;
  const [isLoading, setLoading] = useState(false);
  const [gps, setGps] = useState();

  useEffect((prevProps) => {
    getDataGps();

    // getDataGps();
  }, []);

  const getDataGps = () => {
    // setLoading(true);

    props.getWeather(paidHive.city);

    // const hive = _.clone({
    //   hiveId: selectedHive,
    // });
    // props.getGps().finally(() => {
    //   if (status == "succes") {
    //     props.getWeather("condrieu");
    //     setLoading(false);
    //   }
    //   if (status == "error") {
    //   }
    // });
  };

  return (
    <>
      <div className="border-0">
        {/* Page content */}
        <Container className="mt-5" fluid>
          <Card
            className="shadow border-0 overflow text-center"
            //  style={{ backgroundColor: weatherInfo[weather.weather].color }}
            style={
              weather
                ? { backgroundColor: weatherInfo[weather.weather].color }
                : null
            }
          >
            {!weather ? (
              <div className="m-2">
                {!isLoading ? (
                  <h2 className="text-uppercase ">No data</h2>
                ) : (
                  <h2 className="text-uppercase ">Loading...</h2>
                )}
              </div>
            ) : (
              <div className="m-2">
                <Row>
                  <Col md="12" className="mt-2">
                    <h2 className="text-white">{weather.city}</h2>
                    <h3 className="mt--3 font-weight-light text-white">
                      {weatherInfo[weather.weather].title}
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col md="6" xs="6">
                    <img
                      className="embed-responsive ml-5"
                      src={weatherInfo[weather.weather].image.default}
                      alt="weather"
                      style={{ width: "100%" }}
                    />
                  </Col>
                  <Col md="6" xs="6" className="mt-5">
                    <Icon
                      path={weatherInfo[weather.weather].icon}
                      size={1.5}
                      color={"white"}
                    />
                    <h1 className="mt--1 text-white">
                      {weather.temperature}°C
                    </h1>
                    <br></br>
                    <Icon path={mdiWeatherWindy} size={1.5} color={"white"} />
                    <h3 className="mt--1 text-white">
                      {weather.wind}
                      m/s
                    </h3>
                  </Col>
                </Row>
              </div>
            )}
          </Card>
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.langReducer.language,
    status: state.dataReducer.status,
    errors: state.dataReducer.errors,
    temperatures: state.dataReducer.temperatures,
    statusAuth: state.authReducer.status,
    hives: state.hiveReducer.hives,
    weather: state.dataReducer.weather,
    gps: state.dataReducer.gps,
    paidHive: state.hiveReducer.paidHive,
  };
};
export default connect(mapStateToProps, {
  setLangSelected,
  getGps,
  getWeather,
})(Weather);
