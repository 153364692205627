import React from "react";
import { Redirect } from "react-router-dom";
//lang
import { connect } from "react-redux";
import { translation } from "../I18n/i18n";
import { setLangSelected } from "../redux/actions/lang";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  FormText,
  Form,
  Input,
  InputGroup,
  Collapse,
  Row,
  Col,
} from "reactstrap";

//redux
import CryptoJS from "crypto-js";
import { KEY_PW } from "../constants";
import { login } from "../redux/actions/auth";

import * as _ from "lodash";

import ForgotPasswordForm from "components/Form/ForgotPasswordForm.js";
import LoginForm from "components/Form/LoginForm.js";

class Login extends React.Component {
  _isMounted = false;

  state = {
    forgotOpen: false,
  };

  onForgot = () => {
    this.setState({
      forgotOpen: !this.state.forgotOpen,
    });
  };

  render() {
    const { language, user } = this.props;
    const { forgotOpen } = this.state;
    return (
      <>
        {user ? <Redirect push to="/dashboard" /> : null}
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center">
                <img
                  src={require("assets/img/brand/logo4.svg").default}
                  style={{ width: "40%" }}
                />
              </div>

              <Collapse isOpen={!forgotOpen}>
                <LoginForm />
              </Collapse>

              <Collapse isOpen={forgotOpen}>
                <ForgotPasswordForm />
              </Collapse>
            </CardBody>
          </Card>

          <Row className="mt-3">
            <Col xs="6">
              <a className="text-gray" onClick={this.onForgot}>
                {forgotOpen ? (
                  <small> {translation(language, "CONNECT.login?")}</small>
                ) : (
                  <small> {translation(language, "CONNECT.forgot")}</small>
                )}
              </a>
            </Col>
            <Col className="text-right" xs="6">
              <a className="text-gray" href="register">
                <small> {translation(language, "CONNECT.register?")}</small>
              </a>
            </Col>
          </Row>
        </Col>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    language: state.langReducer.language,
    user: state.authReducer.user,
  };
};
export default connect(mapStateToProps, { setLangSelected, login })(Login);
